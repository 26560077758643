import Tree from '../TreeParser/Tree'
import Generate from '../TreeParser/Generate'

/**
 * Class to compute truth table
 */
class ComputeTruthTable {
    /**
     * Constructor for ComputeTruthTable
     * @param {string} equation Equation to put into the truth table
     * @param {Boolean} compressed whether truth table has less columns (compressed)
     */
    constructor(equation, compressed = false) {
        this.tree = new Tree(new Generate(equation).evaluate(true))
        this.equation = this.tree.printOrder()
        this.leaves = this.tree.findLeaves()
        this.compressed = compressed
        this.leftSide = []
        this.headers = []
        this.offset = 0
        this.content = []
        this.result = 0
        this.evaluate()
    }

    /**
     * Gets the headers list
     */
    getHeaders() {
        return this.headers
    }

    /**
     * Gets the content
     */
    getContent() {
        return this.content
    }

    /**
     * Gets the offset of the left side
     */
    getOffset() {
        return this.offset
    }

    /**
     * Gets the original equation
     */
    getEquation() {
        return this.equation
    }
    
    /**
     * Calculates the left side of the truth table and the headers
     */
    calculateLeftSideAndHeaders(){
        let variableSet = new Set()
        let leaves = this.leaves

        for(let i = 0; i<leaves.length; i++) {
            if(leaves[i].getData() !== '1' && leaves[i].getData() !== '0') {
                variableSet.add(leaves[i].getData())
            }
        }

        let variables = [...variableSet].sort()
        let leftSide = []
        let headers = []
        var dictVariables = {}

        //depending on amount of variable sets values for correct amount of rows
        switch(variables.length) {
            case 1:
                dictVariables[variables[0]] = ['0','1']
                dictVariables['0'] = ['0','0']
                dictVariables['1'] = ['1','1']
                leftSide.push(['0','1'])
                this.offset = 1
                break;
            case 2:
                dictVariables[variables[0]] = ['0','0','1','1']
                dictVariables[variables[1]] = ['0','1','0','1']
                dictVariables['0'] = ['0','0','0','0']
                dictVariables['1'] = ['1','1','1','1']
                leftSide.push(['0','0','1','1'],['0','1','0','1'])
                this.offset = 2
                break;
            case 3:
                dictVariables[variables[0]] = ['0','0','0','0','1','1','1','1']
                dictVariables[variables[1]] = ['0','0','1','1','0','0','1','1']
                dictVariables[variables[2]] = ['0','1','0','1','0','1','0','1']
                dictVariables['0'] = ['0','0','0','0','0','0','0','0']
                dictVariables['1'] = ['1','1','1','1','1','1','1','1']
                leftSide.push(['0','0','0','0','1','1','1','1'],['0','0','1','1','0','0','1','1'],['0','1','0','1','0','1','0','1'])
                this.offset = 3
                break;
            default:
                dictVariables['0'] = ['0']
                dictVariables['1'] = ['1']
                this.offset = 0
        }
        this.leftSide = leftSide
        this.headers = headers

        //If includes variables add the variables to headers
        if(this.equation.match(/[A-Z]/i)) {
            for(let i in variables) {
                headers.push(variables[i])
            }
        }

        for(let i in leaves) {
            leaves[i].setColumn(dictVariables[leaves[i].data])
        }

        //Split the equation and add it into headers if not compressed
        if(!this.compressed) {
            headers.push.apply(headers, this.equation.split(""))
        } 
    }

    /**
     * Calculates the content, calculates column for each node within the tree
     */
    calculateContent() {
        let tree = this.tree
        let postOrder = this.tree.postOrder(tree.root,[])
        for(let i in postOrder) {
            let node = postOrder[i]
            if(node.isColumnEmpty()) {
                this.calculateOperandResult(node)
            }
        }
    }

    /**
     * Calculates the column for the passed in operator based on it's children
     * @param {Node} operand the operand node to calculate column on
     */
    calculateOperandResult(operand) {
        var column = []
        var childOneColumn = operand.left().column
        if(operand.right() !== undefined) {
            var childTwoColumn = operand.right().column
        }
        switch(operand.getData()) {
            case "•":
                for (let i in childOneColumn) {
                    if(childOneColumn[i] === '1' && childTwoColumn[i] === '1') {
                        column.push('1')
                    } else {
                        column.push('0')
                    }
                }
                break;
            case "+":
                for (let i in childOneColumn) {
                    if(childOneColumn[i] === '0' && childTwoColumn[i] === '0') {
                        column.push('0')
                    } else {
                        column.push('1')
                    }
                }               
                break;
            case "⊕":
                for (let i in childOneColumn) {
                    if ((childOneColumn[i] === '1' || childTwoColumn[i] === '1') && !(childOneColumn[i] === '1' && childTwoColumn[i] === '1'))  {
                        column.push('1')
                    } else {
                        column.push('0')
                    }
                }
                break;
            case "`":
                for (let i in childOneColumn) {
                    if (childOneColumn[i] === '1')  {
                        column.push('0')
                    } else {
                        column.push('1')
                    }
                }
                break;
            default:
        }
        operand.setColumn(column)  
    }

    /**
     * Retreieves all the content to put all together
     */
    finaliseContent() {
        let inOrder = this.tree.inOrder(this.tree.root,[])
        let postOrder = this.tree.postOrder(this.tree.root,[])
        let content = this.leftSide
        let equation = this.equation.split("")
        let headers = this.headers
        if(!this.compressed) {
            //New index not to include brackets
            let j = 0
            for(let i = 0; i<equation.length; i++) {
                if(equation[i] === "(" || equation[i] === ")") {
                    content.push([])
                } else {
                    content.push(inOrder[j].column)
                    j++
                }
            }
        } else {
            let count = 0
            let includes = false
            let operators = ['•','+','⊕','`']
            for(let i = 0; i<postOrder.length; i++) {
                if(operators.includes(postOrder[i].data)) {
                    if(!includes) {
                        includes = true
                    }
                    count++
                    content.push(postOrder[i].column)
                    headers.push(this.tree.printBrackets(postOrder[i],[]))
                }
            }
            this.result = count
        }
        //Traverses content for React to display it
        content = content[0].map((col, i) => content.map(row => row[i]))
        this.content = content
        this.headers = headers
    }

    /**
     * Get's the index of the result node within the equation
     */
    getResult() {
        if(this.compressed) {
            let offset = this.offset - 1
            return this.result + offset
        } else {
            let nodes = this.tree.printOrderAsNodes()
            let resultNum = this.offset
            for (let i = 0; i<nodes.length; i++) {
                if(nodes[i] === this.tree.root) {
                    resultNum += i
                }
            }
            return resultNum
        }
    }

    /**
     * Uses all the methods together to calculate the full truth table
     */
    evaluate() {
        this.calculateLeftSideAndHeaders()
        this.calculateContent()
        this.finaliseContent()
    }
}

export default ComputeTruthTable;