import React, { Component } from 'react'
import { Button } from 'reactstrap';
import EquationTruth from '../../../Tools/ExpressionBuilder/EquationTruth'
import CommuativeCarousel from './Carousel/CommutativeOR'
import AssociateCarousel from './Carousel/AssociateOR'
import DistributiveCarousel from './Carousel/DistributiveOR'

/**
 * Component for displaying part 4 in tutorial 3.
 * 
 * @example
 * return (
 *  <ThreePart4 onComplete={this.onComplete4}/>
 * )
 */
class ThreePart4 extends Component {
    /**
     * Constructor for Part4
     * @param {Function} props.onComplete Called when Part4 of tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "A+0",
            showSecond: false,
            commuativeCarousel: false,
            associateCarousel: false,
            distributiveCarousel: false,
            currentLaw: "OR Form: Identity Law",
            answerEquation: "A",
            information: "With the Identity Law in OR Form, you can see the result column (which is highlighted) equals the same as A, hence A+0=A.",
            identityLaw: "success",
            zeroLaw: "primary",
            inverseLaw: "primary",
            idempotentLaw: "primary",
            absorptionLaw: "primary",
            commutativeLaw: "primary",
            associateLaw: "primary",
            distributiveLaw: "primary"
        }
    }

    /**
     * Changes the law currently
     * @param {string} law name of the current law to change equation to
     */
    changeLaw(law) {
        let zero = this.state.zeroLaw
        let inverse = this.state.inverseLaw
        let idempotent = this.state.idempotentLaw
        let absorption = this.state.absorptionLaw
        let commutative = this.state.commutativeLaw
        let associate = this.state.associateLaw
        let distributive = this.state.distributiveLaw
        switch(law) {
            case "Identity":
                this.setState({
                    equation: "A+0", 
                    showSecond: false,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Identity Law", 
                    answerEquation: "A",
                    information: "With the Identity Law in OR Form, you can see the result column (which is highlighted) equals the same as A, hence A+0=A."
                })
                break;
            case "Zero & One":
                this.setState({
                    equation: "A+1", 
                    showSecond: false,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Zero and One Law",
                    answerEquation: "1",
                    information: "With the Zero and One Law in OR Form, you can see the result column (which is highlighted) all equals to 1, hence A+1=1.",
                    zeroLaw: "success"
                })
                zero = "success"
                break;
            case "Inverse":
                this.setState({
                    equation: "A+A`", 
                    showSecond: false,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Inverse Law", 
                    answerEquation: "1",
                    information: "With the Inverse Law in OR Form, you can see the result column (which is highlighted) all equals to 1, hence A+A`=0.",
                    inverseLaw: "success"
                })
                inverse = "success"
                break;
            case "Idempotent":
                this.setState({
                    equation: "A+A", 
                    showSecond: false,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Idempotent Law", 
                    answerEquation: "A",
                    information: "With the Idempotent Law in OR Form, you can see the result column (which is highlighted) equals the same as A, hence A+A=A.",
                    idempotentLaw: "success"
                })
                idempotent = "success"
                break;
            case "Absorption":
                this.setState({
                    equation: "A+(A•B)",
                    showSecond: false,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Absorption Law",
                    answerEquation: "A",
                    information: "With the Absorption Law in OR Form, you can see the result column (which is highlighted) equals the same as A, hence A+(A•B)=A.",
                    absorptionLaw: "success"
                })
                absorption = "success"
                break;
            case "Commutative":
                this.setState({
                    equation: "A+B",
                    showSecond: true,
                    commuativeCarousel: true,
                    associateCarousel: false,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Commutative Law",
                    answerEquation: "B+A",
                    information: "With the Commutative Law in OR Form, you can see the result column (which is highlighted) within both tables in the carousel is the same, hence A+B=B+A",
                    commutativeLaw: "success"
                })
                commutative = "success"
                break;     
            case "Associate":
                this.setState({
                    equation: "(A+B)+C",
                    showSecond: true,
                    commuativeCarousel: false,
                    associateCarousel: true,
                    distributiveCarousel: false,
                    currentLaw: "OR Form: Associate Law",
                    answerEquation: "A+(B+C)",
                    information: "With the Associate Law in OR Form, you can see the result column (which is highlighted) within both tables in the carousel is the same, hence (A+B)+C=A+(B+C)",
                    associateLaw: "success"
                })
                associate = "success"
                break;      
            case "Distributive":
                this.setState({
                    equation: "(A+B)•C",
                    showSecond: true,
                    commuativeCarousel: false,
                    associateCarousel: false,
                    distributiveCarousel: true,
                    currentLaw: "OR Form: Distributive Law",
                    answerEquation: "(A•C)+(B•C)",
                    information: "With the Distributive Law in OR Form, you can see the result column (which is highlighted) within both tables in the carousel is the same, hence (A+B)•C=(A•C)+(B•C)",
                    distributiveLaw: "success"
                })
                distributive = "success"
                break;           
            default:
                break;
        }
        if (zero === "success" && inverse === "success" && idempotent === "success" && absorption === "success" && commutative === "success" && associate === "success" && distributive === "success") {
            this.props.onComplete()
        }
    }

    /**
     * Displays all OR Form Rules in Truth Tables to explain them
     */
    render() {
        return (
            <div>
                <div className="scenario"> 
                <h4>{this.state.currentLaw}</h4>
                <p>Press the buttons below to take a look at some of the different rules and how they work.</p>
                <Button outline color={this.state.identityLaw} onClick={() => this.changeLaw("Identity")}>Identity</Button>
                <Button outline color={this.state.zeroLaw} onClick={() => this.changeLaw("Zero & One")}>Zero & One</Button>
                <Button outline color={this.state.inverseLaw} onClick={() => this.changeLaw("Inverse")}>Inverse</Button>
                <Button outline color={this.state.idempotentLaw} onClick={() => this.changeLaw("Idempotent")}>Idempotent</Button>
                <Button outline color={this.state.absorptionLaw} onClick={() => this.changeLaw("Absorption")}>Absorption</Button>
                <Button outline color={this.state.commutativeLaw} onClick={() => this.changeLaw("Commutative")}>Commutative</Button>
                <Button outline color={this.state.associateLaw} onClick={() => this.changeLaw("Associate")}>Associate</Button>
                <Button outline color={this.state.distributiveLaw} onClick={() => this.changeLaw("Distributive")}>Distributive</Button>
                </div>

                <div className="scenario">   
                    { !this.state.showSecond && <EquationTruth editable={false} equation={this.state.equation}/>}
                    { this.state.commuativeCarousel && <CommuativeCarousel/> }
                    { this.state.associateCarousel && <AssociateCarousel/> }
                    { this.state.distributiveCarousel && <DistributiveCarousel/> }
                    <p>{this.state.information}</p>

                    <div className="scenario">
                        <h4>Original Equation:</h4>
                        <h2>{this.state.equation}</h2>
                    </div>

                    <div className="scenario">
                        <h4>{this.state.currentLaw} Applied</h4>
                        <h2>{this.state.answerEquation}</h2>
                    </div>
                </div>
            </div> 
        )
    }
}

export default ThreePart4;