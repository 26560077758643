import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, NavLink as RRNavLink } from 'react-router-dom';
import Home from '../Home/Home'
import Expression from '../Tools/ExpressionBuilder/Expression'
import Rules from '../Tools/RuleSimplify/Rules'
import TeachRules from '../Tools/TestRulesSimplify/TeachRules';
import LearnTable from '../Tools/TestTruthTable/TablePage'
import mobileLogo from '../assets/images/mobileLogo.png'
import webLogo from '../assets/images/webLogo.png'
import Tutorial1 from '../Learning/Tutorials/1/OneTutorial'
import Tutorial2 from '../Learning/Tutorials/2/TwoTutorial'
import Tutorial3 from '../Learning/Tutorials/3/ThreeTutorial'
import Tutorial4 from '../Learning/Tutorials/4/FourTutorial'
import Quiz1 from '../Learning/Quizzes/Quiz1'
import Quiz2 from '../Learning/Quizzes/Quiz2'
import Quiz3 from '../Learning/Quizzes/Quiz3'
import Learning from '../Learning/LearnPage'
import { withCookies } from 'react-cookie'
import CookieConsent from "react-cookie-consent";
import '../assets/css/App.css'

/**
 * Component that contains the app and handles base navigation
 */
class App extends React.Component {
	constructor(props) {
		super(props);
		this.closeNavbar = this.closeNavbar.bind(this);
		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true,
			width: window.innerWidth
		};
	}

	/**
	 * Adds an event listener when component is mounted
	 */
	componentDidMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	/**
	 * Removes event listener when component is unmounted
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	/**
	 * Handles when window size changes
	 */
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	/**
	 * used to change the state of nav being collasped when it is pressed
	 */
	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	/**
	 * Closes the navigation bar if on mobile
	 */
	closeNavbar() {
		this.setState({
			collapsed: true
		});
	}

	/**
	 * Render the app to the page, includes Router and Navigation
	 */
	render() {
		const { width } = this.state;
		const isMobile = width <= 480;
		return (
			/**
			 * handles all the navigation within the app, keeps same page changes content
			 * Navigation for mobile and for web
			 */
			<Router>
				{isMobile &&
					<div id="container">
						<Navbar color="dark" className="navbar-dark">
							<NavbarBrand tag={RRNavLink} exact to='/'><img src={mobileLogo} className="mobilogo" alt="logo" /></NavbarBrand>
							<NavbarToggler onClick={this.toggleNavbar} />
							<Collapse isOpen={!this.state.collapsed} navbar>
								<Nav navbar className="mr-auto">
									<NavItem>
										<NavLink onClick={this.closeNavbar} activeClassName='active' tag={RRNavLink} exact to='/'>Tools</NavLink>
									</NavItem>
									<NavItem >
										<NavLink onClick={this.closeNavbar} activeClassName='active' tag={RRNavLink} exact to='/letslearn'>Let's Learn</NavLink>
									</NavItem>
								</Nav>
							</Collapse>
						</Navbar>
						<Switch>
							<Route exact path='/' render={() => (<Home cookies={this.props.cookies}/>)}/>
							<Route exact path='/expression' render={() => (<Expression cookies={this.props.cookies}/>)}/>
							<Route exact path='/rules' render={() => (<Rules cookies={this.props.cookies}/>)}/>
							<Route exact path='/learnRules' render={() => (<TeachRules cookies={this.props.cookies}/>)} />
							<Route exact path='/learnTable' render={() => (<LearnTable cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/1' render={() => (<Tutorial1 cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/2' render={() => (<Tutorial2 cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/3' render={() => (<Tutorial3 cookies={this.props.cookies}/>)} />	
							<Route exact path='/tutorial/4' render={() => (<Tutorial4 cookies={this.props.cookies}/>)} />
							<Route exact path='/quiz/1' render={() => (<Quiz1 cookies={this.props.cookies}/>)} />
							<Route exact path='/quiz/2' render={() => (<Quiz2 cookies={this.props.cookies}/>)} />	
							<Route exact path='/quiz/3' render={() => (<Quiz3 cookies={this.props.cookies}/>)} />
							<Route exact path='/letslearn' render={() => (<Learning cookies={this.props.cookies}/>)} />				
						</Switch>
					</div>}

				{!isMobile &&
					<div id="container">
						<div className="dark-nav">
							<NavbarBrand tag={RRNavLink} exact to='/'><img src={webLogo} className="tc-logo" alt="logo" /></NavbarBrand>
						</div>
						<Navbar color="dark" className="navbar-dark">
							<Nav className="mr-auto">
								<NavLink activeClassName='active' tag={RRNavLink} exact to='/'>Tools</NavLink>
								<NavLink onClick={this.closeNavbar} activeClassName='active' tag={RRNavLink} exact to='/letslearn'>Let's Learn</NavLink>
							</Nav>
						</Navbar>
						<Switch>
							<Route exact path='/' render={() => (<Home cookies={this.props.cookies}/>)}/>
							<Route exact path='/expression' render={() => (<Expression cookies={this.props.cookies}/>)}/>
							<Route exact path='/rules' render={() => (<Rules cookies={this.props.cookies}/>)}/>
							<Route exact path='/learnRules' render={() => (<TeachRules cookies={this.props.cookies}/>)} />
							<Route exact path='/learnTable' render={() => (<LearnTable cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/1' render={() => (<Tutorial1 cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/2' render={() => (<Tutorial2 cookies={this.props.cookies}/>)} />
							<Route exact path='/tutorial/3' render={() => (<Tutorial3 cookies={this.props.cookies}/>)} />	
							<Route exact path='/tutorial/4' render={() => (<Tutorial4 cookies={this.props.cookies}/>)} />
							<Route exact path='/quiz/1' render={() => (<Quiz1 cookies={this.props.cookies}/>)} />
							<Route exact path='/quiz/2' render={() => (<Quiz2 cookies={this.props.cookies}/>)} />	
							<Route exact path='/quiz/3' render={() => (<Quiz3 cookies={this.props.cookies}/>)} />
							<Route exact path='/letslearn' render={() => (<Learning cookies={this.props.cookies}/>)} />				
						</Switch>
					</div>}
					<CookieConsent>
    					This website uses cookies to enhance the user experience.
					</CookieConsent>
			</Router>	
		);
	}
}

export default withCookies(App);