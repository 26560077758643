/**
 * Class for holding information for a Tree
 */
class Tree {
    /**
     * Costructor for Tree
     * @param {Node} root Top Node of the Tree
     */
    constructor(root) {
        this.root = root;
    }

    /**
     * Get's the root of the tree
     */
    getRoot() {
        return this.root;
    }

    /**
     * Sets the root of the tree
     * @param {Node} root Node to set root to
     */
    setRoot(root) {
        this.root = root;
    }

    /**
     * return a list of all Nodes that are leaves
     */
    findLeaves() {
        let list = []
        this.root.findLeaves(this.root, list)
        return list
    }

    /**
     * returns a list of nodes that matches the values within listTypes
     * @param {list} listTypes list of values that want nodes to be
     */
    findNodesOfType(listTypes) {
        let result = []
        let allNodes = this.printLevelOrder()
        for (let i = 0; i < listTypes.length; i++) {
            for (let j = 0; j < allNodes.length; j++) {
                if (allNodes[j].data === listTypes[i]) {
                    result.push(allNodes[j])
                }
            }
        }
        return result
    }

    /**
     * returns a list of nodes that matches the values within listTypes and have two children
     * @param {list} listTypes list of values that want nodes to be
     */
    findNodesOfTypeChildren(listTypes) {
        let result = []
        let allNodes = this.printLevelOrder()
        for (let i = 0; i < listTypes.length; i++) {
            for (let j = 0; j < allNodes.length; j++) {
                if (allNodes[j].data === listTypes[i] && allNodes[j].children.length === 2) {
                    result.push(allNodes[j])
                }
            }
        }
        return result
    }

    /**
     * returns a list of nodes that matches the values within listTypes and have no children
     * @param {list} listTypes list of values that want nodes to be
     */
    NodesTypeOnlyChildren(listTypes) {
        let result = []
        let allNodes = this.printLevelOrder()
        for (let i = 0; i < listTypes.length; i++) {
            for (let j = 0; j < allNodes.length; j++) {
                if (allNodes[j].data === listTypes[i]) {
                    if (allNodes[j].children[0].children.length === 0 && allNodes[j].children[1].children.length === 0) {
                        result.push(allNodes[j])
                    }
                }
            }
        }
        return result
    }

    /**
     * Goes through the tree in level order
     */
    printLevelOrder() {
        let h = this.height(this.root);
        let result = []
        for (let i = 1; i <= h; i++) {
            this.printGivenLevel(this.root, i, result);
        }
        return result
    }

    /**
     * Used to give the height of the tree
     * @param {Node} root root of the Tree
     */
    height(root) {
        if (root == null) {
            return 0;
        } else {
            let lheight = this.height(root.children[0]);
            let rheight = this.height(root.children[1]);
            if (lheight > rheight) {
                return (lheight + 1);
            } else {
                return (rheight + 1);
            }
        }
    }

    /**
     * Print the current level in the Tree
     * @param {Node} root the root Node of the level
     * @param {number} level the index of the level
     * @param {list} result the result that nodes will be added to
     */
    printGivenLevel(root, level, result) {
        if (root === undefined) {
            return;
        }
        if (level === 1) {
            return result.push(root)
        } else if (level > 1) {
            this.printGivenLevel(root.children[0], level - 1, result);
            this.printGivenLevel(root.children[1], level - 1, result);
        }
    }

    /**
     * postOrder traversal of Nodes returned
     * @param {Node} root node that is being passed in
     * @param {list} values postOrder node list that is returned
     */
    postOrder(root, values) {
        if (root !== undefined) {
            this.postOrder(root.children[0], values)
            this.postOrder(root.children[1], values)
            values.push(root)
        }
        return values
    }

    /**
     * inOrder traversal of Nodes returned
     * @param {Node} root node that is being passed in
     * @param {list} values inOrder node list that is returned
     */
    inOrder(root, values) {
        if (root !== undefined) {
            this.inOrder(root.children[0], values)
            values.push(root)
            this.inOrder(root.children[1], values)
        }
        return values
    }


    /**
     * returns a list of inOrder nodes (just values) with brackets added
     */
    printOrder() {
        if (this.root.children.length === 0) {
            return this.root.data
        } else {
            let result = this.printBrackets(this.root, [])
            return result.join("")
        }
    }

    /**
     * returns a list of inOrder node values with brackets added
     * @param {string} root value of node
     * @param {list} result result that is returned
     */
    printBrackets(root, result) {
        let operator = ["•", "+","⊕"]
        if (root === undefined) {
            return
        }
        if(root.children.length === 0) {
            result.push(root.getData())
            return
        }
        if(operator.includes(root.left().getData())) {
            result.push("(")
            this.printBrackets(root.left(), result)
            result.push(")")
        } else {
            this.printBrackets(root.left(), result)
        }
        result.push(root.getData())
        if(root.right() !== undefined) {
            if(operator.includes(root.right().getData())) {
                result.push("(")
                this.printBrackets(root.right(), result)
                result.push(")")
            } else {
                this.printBrackets(root.right(), result)
            }
        }
        return result
    }

    /**
     * returns a list of inOrder nodes with brackets added
     */
    printOrderAsNodes() {
        if (this.root.children.length === 0) {
            return this.root
        } else {
            let result = this.printBracketsAsNodes(this.root, [])
            return result
        }
    }

    /**
     * returns a list of inOrder nodes with brackets added
     * @param {Node} root root node that being passed in
     * @param {list} result result that is returned
     */
    printBracketsAsNodes(root, result) {
        let operator = ["•", "+","⊕"]
        if (root === undefined) {
            return
        }
        if(root.children.length === 0) {
            result.push(root)
            return
        }
        if(operator.includes(root.left().getData())) {
            result.push("(")
            this.printBracketsAsNodes(root.left(), result)
            result.push(")")
        } else {
            this.printBracketsAsNodes(root.left(), result)
        }
        result.push(root)
        if(root.right() !== undefined) {
            if(operator.includes(root.right().getData())) {
                result.push("(")
                this.printBracketsAsNodes(root.right(), result)
                result.push(")")
            } else {
                this.printBracketsAsNodes(root.right(), result)
            }
        }
        return result
    }
}

export default Tree;