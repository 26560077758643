import React, { Component } from 'react'
import { Table } from 'reactstrap'

/**
 * Component for displaying part 2 in tutorial 1.
 * 
 * @example
 * return (
 *  <OnePart2/>
 * )
 */
class OnePart2 extends Component {
    /**
     * Renders a table to explain operators within Boolean Logic
     */
    render() {
        return (
            <div> 
                <h3>Boolean Logic</h3>
                <div className="scenario">
                    <p>To help us understand Boolean Logic, we use four operators that each represent a logical statement.</p>
                    <p><b>These include:</b></p>
                    <Table style={{background: "white"}} bordered size="sm" responsive="sm" >
                        <thead className="truth">
                            <tr>
                                <th>OPERATOR</th>
                                <th>SYMBOL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>AND</th>
                                <td>•</td>
                            </tr>
                            <tr>
                                <th>OR</th>
                                <td>+</td>
                            </tr>
                            <tr>
                                <th>EXCLUSIVE OR</th>
                                <td>⊕</td>
                            </tr>
                            <tr>
                                <th>NOT<sup>1</sup></th>
                                <td>`</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p><sup>1</sup>NOT is sometimes represented as a bar on top of the variable(s), i.e. Ā</p>
                </div> 
            </div>
        )
    }
}

export default OnePart2