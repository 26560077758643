import React, { Component } from 'react';
import Boxable from './Boxable';

/**
 * Components displayed when using scenarios, different letter variables used.
 * 
 * @example
 * return (
 *  <ComponentScenario/>
 * )
 */
class ComponentScenario extends Component {
    /**
     * Renders A,B,C,D,F,H,R,L,1,0,`,•,+,⊕,(,) components in a Boxable
     */
    render() {
        return (
            <div className="things_to_drag">
                <Boxable targetKey="box" label="A" colour="blue" error="" />
                <Boxable targetKey="box" label="B" colour="blue" error="" />
                <Boxable targetKey="box" label="C" colour="blue" error="" />
                <Boxable targetKey="box" label="D" colour="blue" error="" />
                <Boxable targetKey="box" label="F" colour="blue" error="" />
                <Boxable targetKey="box" label="H" colour="blue" error="" />
                <Boxable targetKey="box" label="R" colour="blue" error="" />
                <Boxable targetKey="box" label="L" colour="blue" error="" />
                <Boxable targetKey="box" label="1" colour="blue" error="" />
                <Boxable targetKey="box" label="0" colour="blue" error="" />
                <Boxable targetKey="box" label="`" colour="blue" error="" />
                <Boxable targetKey="box" label="•" colour="blue" error="" />
                <Boxable targetKey="box" label="+" colour="blue" error="" />
                <Boxable targetKey="box" label="⊕" colour="blue" error="" />
                <Boxable targetKey="box" label="(" colour="blue" error="" />
                <Boxable targetKey="box" label=")" colour="blue" error="" />
            </div>
        )
    }
}
export default ComponentScenario;