import React, { Component } from 'react';
import Boxable from './Boxable';

/**
 * Components displayed when simplfying rules, does not include Exclusive Or operator.
 * 
 * @example
 * return (
 *  <Components/>
 * )
 */
class Components extends Component {
    /**
     * Renders A,B,C,1,0,`,•,+,(,) components in a Boxable
     */
    render() {
        return (
            <div className="things_to_drag">
                <Boxable targetKey="box" label="A" colour="blue" error="" />
                <Boxable targetKey="box" label="B" colour="blue" error="" />
                <Boxable targetKey="box" label="C" colour="blue" error="" />
                <Boxable targetKey="box" label="1" colour="blue" error="" />
                <Boxable targetKey="box" label="0" colour="blue" error="" />
                <Boxable targetKey="box" label="`" colour="blue" error="" />
                <Boxable targetKey="box" label="•" colour="blue" error="" />
                <Boxable targetKey="box" label="+" colour="blue" error="" />
                <Boxable targetKey="box" label="(" colour="blue" error="" />
                <Boxable targetKey="box" label=")" colour="blue" error="" />
            </div>
        )
    }
}
export default Components;