import React, { Component } from 'react'
import EquationTruth from '../../../Tools/ExpressionBuilder/EquationTruth'
import { Button } from 'reactstrap';

/**
 * Component for displaying part 1 in tutorial 2.
 * 
 * @example
 * return (
 *  <TwoPart1 onComplete={this.onComplete1}/>
 * )
 */
class TwoPart1 extends Component {
    /**
     * Constructor for Part1
     * @param {Function} props.onComplete Called when Part 1 of tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "A",
            bButton: "primary",
            cButton: "primary",
            zeroButton: "primary",
            oneButton: "primary",
            valueInfo: "Variables can only be off or on.",
        }
    }

    /**
     * Changes the value displayed in the expression builder/truth table
     * @param {string} value the value to display truth table for
     */
    changeValueTable(value) {
        switch(value) {
            case "A":
                this.setState({equation: "A", valueInfo: "Variables can only be off or on."})
                break;
            case "B":
                this.setState({equation: "B", bButton: "success", valueInfo: "Variables can only be off or on."})
                if (this.state.cButton === "success" && this.state.zeroButton === "success" && this.state.oneButton === "success") {
                    this.props.onComplete()
                }
                break;
            case "C":
                this.setState({equation: "C", cButton: "success", valueInfo: "Variables can only be off or on."})
                if (this.state.bButton === "success" && this.state.zeroButton === "success" && this.state.oneButton === "success") {
                    this.props.onComplete()
                }
                break;
            case "0":
                this.setState({equation: "0", zeroButton: "success", valueInfo: "Zero can only be off, so all values will always be 0."})
                if (this.state.cButton === "success" && this.state.bButton === "success" && this.state.oneButton === "success") {
                    this.props.onComplete()
                }
                break;
            case "1":
                this.setState({equation: "1", oneButton: "success", valueInfo: "One can only be on, so all values will always be 1."})
                if (this.state.cButton === "success" && this.state.zeroButton === "success" && this.state.bButton === "success") {
                    this.props.onComplete()
                }
                break;
            default:
                break;
        }
    }

    /**
     * Explains basics of Truth Tables and puts each variable into a truth table
     */
    render() {
        return (
            <div>
                <h3>Truth Tables</h3>
                <div className="scenario">   
                    <p>We can use a truth table to show all the possible input combinations of an equation.</p>
                    <p>Again, True is represented by a 1 and False by a 0.</p>
                    <p>We will take a look at an example truth table for each operator in the next part of the tutorial.</p>
                </div>
                <div className="scenario">
                    <p>Click on each button to see how the value appear in a truth table.</p>
                    <Button outline color="success" onClick={() => this.changeValueTable("A")}>A</Button>
                    <Button outline color={this.state.bButton} onClick={() => this.changeValueTable("B")}>B</Button>
                    <Button outline color={this.state.cButton} onClick={() => this.changeValueTable("C")}>C</Button>
                    <Button outline color={this.state.zeroButton} onClick={() => this.changeValueTable("0")}>0</Button>
                    <Button outline color={this.state.oneButton} onClick={() => this.changeValueTable("1")}>1</Button>
                    <EquationTruth equation={this.state.equation} editable={false}/>
                    <b>{this.state.valueInfo}</b>
                </div>
            </div>
        )
    }
}

export default TwoPart1;