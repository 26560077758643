import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import first from '../../../../assets/images/associateAnd1.JPG'
import second from '../../../../assets/images/associateAnd2.JPG'

/**
 * Component handling the AssociateAND Carousel.
 * 
 * @example
 * return (
 *  <AssociateAND/>
 * )
 */
class AssociateAND extends Component {
    /**
     * Constructor for AssociateAND
     */
    constructor(props) {
        super(props);
        this.state = { 
            activeIndex: 0, 
            items: [    
            {
                src: first,
                altText: 'Slide 1',
                caption: ''
            },
            {
                src: second,
                altText: 'Slide 2',
                caption: ''
            }] };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    /**
     * Sets animation to true
     */
    onExiting() {
        this.animating = true;
    }

    /**
     * Sets animation to false
     */
    onExited() {
        this.animating = false;
    }

    /**
     * Go to the next image/index
     */
    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    /**
     * Go to the previous image/index
     */
    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    /**
     * Change index of image to new one passed in
     * @param {number} newIndex 
     */
    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    /**
     * Render the Carousel using the state information
     */
    render() {
        const { activeIndex } = this.state;

        //Done for each item within items, creates slides for Carousel
        var slides = this.state.items.map((item) => {
            return (
                <CarouselItem interval={false} 
                    autoPlay={false}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}>
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption captionText={item.caption} />
                </CarouselItem>
            );
        });

        return (
                <Carousel 
                    interval={false} 
                    enableTouch={true}
                    autoPlay={false}
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
        );
    }
}


export default AssociateAND;