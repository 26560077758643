import React, { Component } from 'react'
import EquationTruth from '../../../Tools/ExpressionBuilder/EquationTruth'
import { Button } from 'reactstrap';

/**
 * Component for displaying part 3 in tutorial 2.
 * 
 * @example
 * return (
 *  <TwoPart3 onComplete={this.onComplete3}/>
 * )
 */
class TwoPart3 extends Component {
    /**
     * Constructor for Part3
     * @param {Function} props.onComplete Called when this part of tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "A`",
            twoButton: "primary",
            threeButton: "primary",
            numVariables: "1",
            answerVariables: "2"
        }
    }

    /**
     * Changes the amount of variables to display in truth table within part 3
     * @param {number} number number of variables to display in truth table
     */
    changeVariables(number) {
        switch(number) {
            case 1:
                this.setState({equation: "A`", numVariables: "1", answerVariables: "2"})
                break;
            case 2:
                this.setState({equation: "A+B", twoButton: "success", numVariables: "2", answerVariables: "4"})
                if (this.state.threeButton === "success") {
                    this.props.onComplete()
                }
                break;
            case 3: 
                this.setState({equation: "A•B+C", threeButton: "success", numVariables: "3", answerVariables: "8"})
                if (this.state.twoButton === "success") {
                    this.props.onComplete()
                }
                break;
            default:
        }
    }

    /**
     * Renders different amount of variables into a truth table
     */
    render() {
        return (
            <div>
                <h3>How many inputs?</h3>
                <div className="scenario">
                    <p>The Truth Table has as many rows as there are inputs, it has all the possible combinations.</p>
                    <Button outline color="success" onClick={() => this.changeVariables(1)}>One</Button>
                    <Button outline color={this.state.twoButton} onClick={() => this.changeVariables(2)}>Two</Button>
                    <Button outline color={this.state.threeButton} onClick={() => this.changeVariables(3)}>Three</Button>
                    <p>See how many combinations depending on the number of variables using the buttons above.</p>
                    <EquationTruth editable={false} equation={this.state.equation}/>
                    <p>As you can see the number of combinations is 2<sup>n</sup>, n being the amount of variables.</p>
                    <p>So in this case, 2<sup>{this.state.numVariables}</sup> = {this.state.answerVariables} combinations.</p>
                </div>
            </div>
        )
    }
}

export default TwoPart3;