/**
 * Class to adds brackets to the equation based on precedence
 */
class Brackets {
    /**
     * Constructor for Brackets
     * @param {string} equation Equation to add in brackets too
     */
    constructor(equation) {
      this.equation = equation
    }
  
    popNext(input, operator) {
      if (input[0] === operator) {
        input.shift();
        return true
      } else {
        return false
      }
    }
  
    popBefore(input, operator) {
      if (input[1] === operator) {
        input.splice(1, 1);
        return true
      }
      return false
    }
  
    parseBinary(input, operator, next) {
      let add
      if (next === "and") {
        add = [this.parseAnds(input)];
      } else if (next === "or") {
        add = [this.parseOrs(input)];
      } else if (next === "not") {
        add = [this.parseNots(input)];
      }
      while (this.popNext(input, operator)) {
        if (next === "and") {
          add.push(this.parseAnds(input));
        } else if (next === "or") {
          add.push(this.parseOrs(input));
        } else if (next === "not") {
          add.push(this.parseNots(input));
        }
      }
      if (add.length > 1) {
        return "(" + add.join(operator) + ")"
      } else {
        return add[0]
      }
    }
  
    parseOrs(input) {
      return this.parseBinary(input, '+', "and");
    }
  
    parseAnds(input) {
      return this.parseBinary(input, '•', "not");
    }
  
    parseExclusives(input) {
      return this.parseBinary(input, '⊕', "or");
    }
  
    parseNots(input) {
      if (this.popBefore(input, "`")) {
        return  "(" + this.parseNots(input) + "`)"
      }
      return this.parsePrimary(input)
    }
  
    parsePrimary(input) {
      if (this.popNext(input, '(')) {
        let e = this.parseOrs(input);
        this.popNext(input, ')')
        while (this.popNext(input, '`')) {
          e = '(' + e + '`)'
        }
        return e
      }
      return input.shift()
    }
  
    /**
     * Puts all functions together to evaluate (adding the brackets)
     */
    evaluate() {
      let result = this.equation
      if (result.length > 1) {
        result = this.parseExclusives(this.equation.split(""))
        if (result !== undefined && result[result.length - 1] === ")") {
          result = result.substring(1, result.length - 1)
        }
      }
      return result
    }
  }
  
  export default Brackets;
  