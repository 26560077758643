import React, { Component } from 'react'
import RulesTable from '../../../Tools/RuleSimplify/RulesTable'

/**
 * Component for displaying part 2 in tutorial 3.
 * 
 * @example
 * return (
 *  <ThreePart2/>
 * )
 */
class ThreePart2 extends Component {
    /**
     * Displays the Rules Table
     */
    render() {
        return (
            <div className="scenario">
                <h3>Rules Table</h3>
                <RulesTable/>
                <br/>
            </div>
        )
    }
}

export default ThreePart2;