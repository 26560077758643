import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Card, Button, CardBody, CardText, CardImg, CardHeader } from 'reactstrap';
import firstImage from '../assets/images/building.png'
import secondImage from '../assets/images/truth.png'
import thirdImage from '../assets/images/simplify.png'
import fourthImage from '../assets/images/maze.png'

/**
 * Component to display the Home page.
 */
class Home extends Component {

  /**
  * When mounted scroll to top of page
  */
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  /**
   * Includes information about app and card for each tool
   */
  render() {
    return (
      <div className="inner-container">
        <div style={{textAlign:"center"}}>
          <h2>Welcome to Technocamps</h2>
          <p>Our mission is to <b>inspire</b>, <b>motivate</b> and <b>engage</b> people with computational thinking and promote Computer Science as underpinning all aspects of modern society.</p>
          <br/>
          <h4>Let's Learn</h4>
          <p>Here there are four tutorials and three quizzes. If you don't have any prior knowledge in boolean algebra, these will teach you everything you need to know.
            <br/> Start at tutorial 1 and make your way through every tutorial and quiz.
          </p>
        </div>
        <br/>
        <div className="content">
          <h2>Tools</h2>
          <div className="scenario">
           <Container>
              <Row>
              <Col md="6" style={{padding: '10px'}}> 
                <Card>
                  <CardHeader>Expression Builder</CardHeader>
                  <br/>
                  <CardImg style={{width:"25%", alignSelf:"center"}} src={firstImage}/>
                  <CardBody>
                    <CardText>Here you can build your own Boolean Expression. If your equation is incorrect, the values that are wrong appear red. Once the equation is correct, the corresponding truth table will appear.</CardText>
                    <Link to='/expression'><Button color="secondary">Let's go!</Button></Link>
                  </CardBody>
                </Card>             
              </Col>

              <Col md="6" style={{padding: '10px'}}>
                <Card>
                  <CardHeader>Test Truth Table</CardHeader>
                  <br/>
                  <CardImg style={{width:"25%", alignSelf:"center"}} src={secondImage}/>
                  <CardBody>
                    <CardText>Once a correct equation is entered, the corresponding truth table will appear with missing values depending on the difficulty. Try to complete every difficulty and get them correct.</CardText>
                    <Link to='/learnTable'><Button color="secondary">Let's go!</Button></Link>
                  </CardBody>
                </Card>
              </Col>
              </Row>

              <Row>
                <Col md="6" style={{padding: '10px'}}> 
                  <Card>
                    <CardHeader>Rules Simplifier</CardHeader>
                    <br/>
                    <CardImg style={{width:"30%", alignSelf:"center"}} src={thirdImage}/>
                    <CardBody>
                      <CardText>Once a correct equation is entered, possible rules that you can apply to your equation will appear. Click the rules to try simplify your equation as much as you can.</CardText>
                      <Link to='/rules'><Button color="secondary">Let's go!</Button></Link>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6" style={{padding: '10px'}}>
                  <Card>
                    <CardHeader>Test Rules Simplifier</CardHeader>
                    <br/>
                    <CardImg style={{width:"25%", alignSelf:"center"}} src={fourthImage}/>
                    <CardBody>
                      <CardText>Once a correct equation has been entered you can find rules and apply them. Once a rule has been clicked you can enter what you think the answer should be, testing you more.</CardText>
                      <Link to='/learnRules'><Button color="secondary">Let's go!</Button></Link>
                    </CardBody>
                  </Card>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;