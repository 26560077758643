import Node from './Node';
import Brackets from './Brackets'
import Tree from './Tree';

/**
 * Generates a Tree for the current equation
 */
class Generate {
    /**
     * Constructor for Generate
     * @param {string} equation Equation to generate tree from
     */
    constructor(equation, addBrackets = true) {
        if (addBrackets) {
            this.equation = new Brackets(equation).evaluate()
        } else {
            this.equation = equation
        }
        this.notation = ["•", "+","`", "⊕"]
        this.variables = ["A", "B", "C", "D", "F", "H", "C", "R", "L", "S", "T", "1", "0"]
        this.operators = [this.notation[0], this.notation[1], this.notation[2], this.notation[3]]
        this.notOp = this.notation[2]

    }

    /**
     * Removes the outer brackets from the string
     * @param {string} string the string to remove brackets from
     */
    removeOuterBrackets(string) {
        string = string.split("");
    
        if(string.includes("(") || string.includes(")")) {
            let brackets = []
            let lastPopped = 1
            let lastBracket = 0
            for(let i=0; i<string.length; i++) {
                if(string[i] === "(") {
                    brackets.push(i)
                } else if (string[i] === ")") {
                    lastPopped = brackets.pop()
                    lastBracket = i
                }
            }
            if (lastPopped === 0 && lastBracket === string.length-1) {
                string.splice(0, 1);
                string.splice(string.length - 1, 1);
            } 
        }
    
        string = string.join("");
        return string;
    }

    /**
     * Recursively generates a tree
     * @param {string} equation current equation passed in
     */
    generateTree(equation) {
        //If equation is one variable, create a Node for it and return it
        if (this.variables.includes(equation)) {
            return new Node(equation);
            //If equation is length two, add a Not operator with the variable as a child
         } else if (equation.length === 2) {
            let notNode = new Node(this.notOp);
            notNode.addChild(new Node(equation[0]));
            return notNode;
         } else {
            let bracketsCounted = 0;
            for (let i = 0; i < equation.length; i++) {
                let value = equation[i];
                if (value === "(") {
                    bracketsCounted += 1;
                } else if (value === ")") {
                    bracketsCounted -= 1; 
                    //if value is an operator within no brackets
                } else if (this.operators.includes(value) && bracketsCounted === 0) {
                    //if there is a double not at the end, add a not then the rest as a child
                     if(equation[equation.length - 1] === this.notOp && equation[equation.length - 2] === this.notOp) {
                        let op = new Node(this.notOp)
                        op.addChild(this.generateTree(equation.substring(0, equation.length-1)))
                        return op 
                        //else calculate left and right side and add as children
                    } else {
                        let left = this.removeOuterBrackets(equation.substring(0, i));
                        let right = this.removeOuterBrackets(equation.substring(i + 1, equation.length));
                        let op = new Node(value);
                        op.addChild(this.generateTree(left));
                        if(right !== "") {
                            op.addChild(this.generateTree(right));
                        }
                        return op;
                    }
                }
            }
        }
    }

    /**
     * Evaluate returns a generated tree using the current equation
     */
    evaluate(correct) {
        if(correct) {
            return this.generateTree(this.equation)
        } else {
            return new Tree()
        }
    }
}

export default Generate;
