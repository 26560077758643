/**
 * Class Validate checks if equation is correct or not, adds errors if not
 */
class Validate {
    /**
     * Constructor for Validate
     * @param {List} items list of items displayed in Box, equation to validate
     */
    constructor(items) {
        this.items = items
    }

    /**
     * check if the operators and operands are allowed to be next to each other, ignores the brackets
     */
    checkIfCorrect() {
        let operands = ["A", "B", "C", "D", "F", "H", "C", "R", "L", "S", "T", "1", "0"];
        let operators = ["•", "+", "`", "⊕"];
        let negation = ["A", "B", "C", "D", "F", "H", "C", "R", "L", "S", "T", "1","0"];
        let newItems = [];
        let previous = null;
        //goes through each item (in the equation)
        for (let i = 0; i < this.items.length; i++) {
            let label = this.items[i].label;
            let uid = this.items[i].uid;
            //special case for negation
            if (label === "`") {
                if (negation.includes(previous)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                    previous = "A";
                } else {
                    newItems.push({ label: label, uid: uid, colour: 'red', error: "Negation needs to come after a variable or brackets" });
                }
                //if nothing before but is operand it is allowed
            } else if (operands.includes(label) && previous === null) {
                previous = label;
                newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                //if the last value is an operator it isn't allowed
            } else if (i === this.items.length - 1 && operators.includes(label)) {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Last value cannot be an operator" });
                previous = label;
                //if it's an operator and operand before it, it's allowed
            } else if (operators.includes(label)) {
                if (operands.includes(previous)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                    previous = label;
                } else {
                    newItems.push({ label: label, uid: uid, colour: 'red', error: "Must have operand before it" });
                }
                //if it is an operand and operator is before it is allowed
            } else if (operands.includes(label)) {
                if (operators.includes(previous)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                    previous = label;
                } else {
                    newItems.push({ label: label, uid: uid, colour: 'red', error: "Must have operator before it" });
                }
                //anything else is not allowed -> brackets set to red for now
            } else {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Brackets are unnecessary" });
            }
        }
        //set the new items 
        this.items = newItems
    };

    /**
     * checks if brackets are correct compared to operands & operators, brackets ignore presence of each other
     */
    checkCorrectBrackets() {
        let operands = ["A", "B", "C", "D", "F", "H", "C", "R", "L", "S", "T", "1", "0", "`", "(", ")"];
        let operators = ["•", "+", "⊕", ")", "("];
        let afterBrackets = ["•", "+", "⊕", ")", "(", "`"];
        let newItems = [];
        let previous = null;
        let length = this.items.length;
        //goes through each item in  (the equation)
        for (let i = 0; i < length; i++) {
            let label = this.items[i].label;
            let uid = this.items[i].uid;
            //brackets are unncessary if only one item within them  
            if (label === "(" && this.items[i + 2] != null && this.items[i + 2].label === ")") {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Brackets are unnecessary, only one value inside" });
            } else if (label === ")" && this.items[i - 2] != null && this.items[i - 2].label === "(") {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Brackets are unnecessary, only one value inside" });
                //if () is present it isn't necessary
            } else if (label === "(" && this.items[i + 1] != null && this.items[i + 1].label === ")") {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Brackets are unnecessary, nothing inside" });
            } else if (label === ")" && this.items[i - 1] != null && this.items[i - 1].label === "(") {
                newItems.push({ label: label, uid: uid, colour: 'red', error: "Brackets are unnecessary, nothing inside" });
                //if last value is a closing bracket and before is an operand it is allowed
            } else if (i === this.items.length - 1 && label === ")") {
                if (operands.includes(previous)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                } else {
                    newItems.push(this.items[i]);
                }
                //last item is opening bracket it's not allowed 
            } else if (i === this.items.length - 1 && label === "(") {
                newItems.push(this.items[i]);
                //if start with opening bracket and next is operand it's allowed  
            } else if (label === "(" && previous == null && this.items[i + 1] != null) {
                if (operands.includes(this.items[i + 1].label)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                } else {
                    newItems.push(this.items[i]);
                }
                //if opening bracket not first, & operator before & operand after it's allowed
            } else if (label === "(" && previous != null) {
                if (operators.includes(previous) && operands.includes(this.items[i + 1].label)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                } else {
                    newItems.push(this.items[i]);
                }
                //if closing bracket not first, & operands before & operator after it's allowed
            } else if (label === ")" && previous != null) {
                if (operands.includes(previous) && afterBrackets.includes(this.items[i + 1].label)) {
                    newItems.push({ label: label, uid: uid, colour: 'green', error: "" });
                } else {
                    newItems.push(this.items[i]);
                }
                //anything but a bracket push as was before and set it to previous  
            } else {
                previous = label;
                newItems.push(this.items[i]);
            }
        }
        //set the newItems as the equation
        this.items = newItems
    }

    /**
     * Checks if brackets are balanced, e.g. if opening matches a closing
     */
    checkBalancedBrackets() {
        let brackets = [];
        let newItems = [];
        //goes through each item in  (the equation)
        for (let i = 0; i < this.items.length; i++) {
            let label = this.items[i].label;
            let uid = this.items[i].uid;
            let colour = this.items[i].colour;
            let error = this.items[i].error;
            //if opening bracket, push it to brackets & new items as it is
            if (label === "(") {
                brackets.push({ label: label, uid: uid, colour: colour, error: error });
                newItems.push({ label: label, uid: uid, colour: colour, error: error });
                //if closing bracket, check there was previously an open
            } else if (label === ")") {
                if (brackets.length === 0) {
                    newItems.push({ label: label, uid: uid, colour: 'red', error: "Closing bracket must have opening before it" });
                } else {
                    let opening = brackets.pop();

                    if (this.items.length > 3 && i === this.items.length - 1 && opening.uid === this.items[0].uid) {
                        newItems.push({ label: label, uid: uid, colour: 'red', error: "unnecessary brackets surrounding the equation" });
                        newItems[0] = { label: "(", uid: opening.uid, colour: 'red', error: "unnecessary brackets surrounding the equation" };
                    } else {
                        newItems.push({ label: label, uid: uid, colour: colour, error: error });
                    }
                }
                //everything else add as it is
            } else {
                newItems.push({ label: label, uid: uid, colour: colour, error: error });
            }
        }
        //open bracket without a matching closing isn't allowed
        if (brackets.length !== 0) {
            for (let i = 0; i < brackets.length; i++) {
                let currentuid = brackets[i].uid;
                const index = newItems.findIndex((item) => {
                    return item.uid === currentuid
                });
                newItems[index] = { label: "(", uid: currentuid, colour: 'red', error: "Opening bracket needs matching closing bracket" };
            }
        }
        //set the new  of items/equation
        this.items = newItems
    }

    /**
     * Evaluates the current equation uses all validation functions
     */
    evaluate() {
        this.checkIfCorrect();
        this.checkCorrectBrackets();
        this.checkBalancedBrackets();
        return this.items
    }
}

export default Validate;