import React, { Component } from 'react'

/**
 * Component for displaying part 1 in tutorial 1.
 * 
 * @example
 * return (
 *  <OnePart1/>
 * )
 */
class OnePart1 extends Component {
    /**
     * Displays content about meaning of Boolen
     */
    render() {
        return (
            <div>
                <h3> Boolean</h3>
                <div className="scenario">
                    <p>Boolean is a data type that holds the value either True or False.</p>
                    <p>True is represented by 1, and False by 0.</p>
                    <p>Logic that we apply in our computers that return True or False is known as Boolean Logic.</p>
                    <p>We will look at Boolean Logic in the next part of the tutorial.</p>
                </div>
            </div>
        )
    }
}

export default OnePart1;