import React, { Component } from 'react'
import {Redirect, Link} from 'react-router-dom'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import Part1 from './ThreePart1';
import Part2 from './ThreePart2';
import Part3 from './ThreePart3';
import Part4 from './ThreePart4';

/**
 * Component handling logic and display for Tutorial 3.
 * 
 * @example
 * return (
 *  <ThreeTutorial cookies={this.props.cookies}/>
 * )
 */
class ThreeTutorial extends Component {
    /**
     * Constructor for Tutorial3
     * @param {Cookies} props.cookies Cookies to set when tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            showPage1: true,
            showPage2: false,
            showPage3: false,
            showPage4: false,
            tutorialDone: false,
            cookies: props.cookies,
            button1: "warning",
            button2: "secondary",
            button3: "secondary",
            button4: "secondary",
            part3: false
        }
        this.onComplete3 = this.onComplete3.bind(this)
        this.onComplete4 = this.onComplete4.bind(this)
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('tutorial3') || this.state.cookies.get('code')) {
            this.setState({tutorialComplete: true})
        }
    }

    /**
     * Tutorial is split into three parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, showPage3: false, showPage4: false, button1: "warning", button2: "secondary", button3: "secondary", button4: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, showPage3: false, showPage4: false, button1: "secondary", button2: "warning", button3: "secondary", button4: "secondary"})
                break;
            case 3:
                this.setState({showPage1: false, showPage2: false, showPage3: true, showPage4: false, button1: "secondary", button2: "secondary", button3: "warning", button4: "secondary"})
                break;  
            case 4:
                this.setState({showPage1: false, showPage2: false, showPage3: false, showPage4: true, button1: "secondary", button2: "secondary", button3: "secondary", button4: "warning"})
                break;
            default:
                break;
        }
    }

    /**
     * Used for when Part3 of tutorial is complete
     */
    onComplete3() {
        this.setState({part3: true})
    }

    /**
     * Used for when Part4 of tutorial is complet, sets tutorial to finished
     */
    onComplete4() {
        this.setState({tutorialComplete: true})
        this.state.cookies.set('tutorial3','true', {path: '/'})
    }

    /**
     * Renders display of tutorial 3, displays seperate parts with navigation at bottom
     */
    render() {
        if(this.state.cookies.get('quiz2') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <div className="content">
                            <Row>
                                <Col>
                                    { this.state.showPage1 && <Part1/> }
                                    { this.state.showPage2 && <Part2/> }
                                    { this.state.showPage3 && <Part3 onComplete={this.onComplete3}/>}
                                    { this.state.showPage4 && <Part4 onComplete={this.onComplete4}/>}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.tutorialComplete && 
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>
                                            <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>   
                                            <Button color={this.state.button4} onClick={() => this.changePage(4)}>4</Button>                    
                                        </ButtonGroup>
                                    <Link to='/letslearn'><Button>Menu</Button></Link>
                                    </ButtonToolbar>}

                                    {!this.state.tutorialComplete && !this.state.showPage1 &&
                                        <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                            <ButtonGroup>
                                                <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                                <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                                {(this.state.showPage3 || this.state.showPage4) && <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>}
                                                {this.state.showPage4 && <Button color={this.state.button4} onClick={() => this.changePage(4)}>4</Button>}
                                            </ButtonGroup>
                                            { this.state.showPage2 && <Button onClick={() => this.changePage(3)}>Next</Button>}
                                            { this.state.showPage3 && this.state.part3 && <Button onClick={() => this.changePage(4)}>Next</Button>}
                                        </ButtonToolbar>}
                                    { !this.state.tutorialComplete && this.state.showPage1 && <Button onClick={() => this.changePage(2)}>Next</Button>}
                                </Col>
                            </Row>
                            </div>
                    </Container>
                </div>  
            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default ThreeTutorial;