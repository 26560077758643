import React, { Component } from 'react'
import {Button} from 'reactstrap'
import { Container, Row, Col } from 'reactstrap';
import Box from '../../Tools/DragDrop/Box'
import ComponentsScenario from '../../Tools/DragDrop/ComponentsScenario'
import {Redirect, Link} from 'react-router-dom'

/**
 * Component handling logic and display for quiz 1.
 * 
 * @example
 * return (
 *  <Quiz1 cookies={this.props.cookies}/>
 * )
 */
class Quiz1 extends Component {
    /**
     * Constructor for Quiz1
     * @param {Cookies} props.cookies Cookies to set when quiz is done
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "",
            oneEquation: "",
            twoEquation: "",
            threeEquation: "",
            fourEquation: "",
            scenario: "Scenario 1",
            scenarioInfo: "We can go outside if it is Friday and we have done our homework.",
            scenarioVariables: "F represents it is Friday and H represents our homework being done.",
            answer: "F•H",
            button: "Check Answer",
            checkButton: "primary",
            oneButton: "primary",
            twoButton: "primary",
            threeButton: "primary",
            fourButton: "primary",
            scenariosDone: false,
            currentScenario: 1,
            possibleHint: "",
            countWrong: 0,
            cookies: props.cookies,
            quizComplete: false
        }
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('quiz1') || this.state.cookies.get('code')) {
            this.setState({quizComplete: true})
        }
    }

    /**
     * Changes state equation binding to the Drag and Drop box
     * @param {string} newEquation the new equation state will be set to
     */
    onChangeEquation(newEquation) {
        this.setState({
            equation: newEquation
        });
    }

    /**
     * Handling the switching between scenarios
     * @param {number} number the index/number of the scenario that state will be set to
     */
    changeScenario(number) {
        switch(this.state.currentScenario) {
            case 1:
                this.setState({oneEquation: this.state.equation})
                break;
            case 2:
                this.setState({twoEquation: this.state.equation})
                break;
            case 3:
                this.setState({threeEquation: this.state.equation})
                break;
            case 4:
                this.setState({fourEquation: this.state.equation})
                break;
            default:
                break;
        }

        switch(number) {
            case 1:
                this.setState({
                    scenario: "Scenario 1", 
                    equation: this.state.oneEquation,
                    scenarioInfo: "We can go outside if it is Friday and we have done our homework.", 
                    answer: "F•H", 
                    button: "Check Answer", 
                    scenarioVariables: "F represents it is Friday and H represents our homework being done.", 
                    currentScenario: 1,
                    possibleHint: "",
                    countWrong: 0,
                    checkButton: "primary"
                })
                break;
            case 2:
                this.setState({
                    scenario: "Scenario 2", 
                    equation: this.state.twoEquation,
                    scenarioInfo: "You can wear a coat if it is cold or if it is raining.", 
                    answer: "C+R", 
                    button: "Check Answer", 
                    scenarioVariables: "C represents it being cold and R represents it raining.", 
                    currentScenario: 2,
                    possibleHint: "",
                    countWrong: 0,
                    checkButton: "primary"
                })
                break;
            case 3:
                this.setState({
                    scenario: "Scenario 3", 
                    equation: this.state.threeEquation,
                    scenarioInfo: "You can drive or take a bus to school.", 
                    answer: "D⊕B", 
                    button: "Check Answer", 
                    scenarioVariables: "D represents driving to school and B represents taking the bus.", 
                    currentScenario: 3,
                    possibleHint: "",
                    countWrong: 0,
                    checkButton: "primary"
                })
                break;
            case 4:
                this.setState({
                    scenario: "Scenario 4", 
                    equation: this.state.fourEquation,
                    scenarioInfo: "You can either lock a door or not lock a door.", 
                    answer:"L⊕L`", 
                    button: "Check Answer", 
                    scenarioVariables: "L represents the door being locked.", 
                    currentScenario: 4,
                    possibleHint: "",
                    countWrong: 0,
                    checkButton: "primary"
                })
                break;
            default:
        }
    }

    /**
     * Checks if answer of current scenario is correct and adjusts state accordingly
     */
    checkAnswer() {
        if(this.state.answer === this.state.equation) {
            switch(this.state.currentScenario) {
                case 1:
                    this.setState({oneButton: "success", button: "Well Done!", countWrong: 0, possibleHint: ""})
                    if(this.state.twoButton === "success" && this.state.threeButton === "success" && this.state.fourButton === "success") {
                        this.setState({scenariosDone: true})
                        this.state.cookies.set('quiz1','true', {path: '/'})
                    }
                    break;
                case 2:
                    this.setState({twoButton: "success", button: "Well Done!", countWrong: 0, possibleHint: ""})
                    if(this.state.oneButton === "success" && this.state.threeButton === "success" && this.state.fourButton === "success") {
                        this.setState({scenariosDone: true})
                        this.state.cookies.set('quiz1','true', {path: '/'})
                    }
                    break;
                case 3:
                    this.setState({threeButton: "success", button: "Well Done!", countWrong: 0, possibleHint: ""},)
                    if(this.state.oneButton === "success" && this.state.twoButton === "success" && this.state.fourButton === "success") {
                        this.setState({scenariosDone: true})
                        this.state.cookies.set('quiz1','true', {path: '/'})
                    }
                    break;
                case 4:
                    this.setState({fourButton: "success", button: "Well Done!", countWrong: 0, possibleHint: ""})
                    if(this.state.oneButton === "success" && this.state.twoButton === "success" && this.state.threeButton === "success") {
                        this.setState({scenariosDone: true})
                        this.state.cookies.set('quiz1','true', {path: '/'})
                    }
                    break;
                default:
                    break;
            }
            this.setState({button: "Well Done!", checkButton: "success"})
        } else {
            let wrongAmount = this.state.countWrong + 1
            if(wrongAmount > 1) {
                if ((this.state.currentScenario === 3 && this.state.equation.includes("+")) 
                || (this.state.currentScenario === 2 && this.state.equation.includes("⊕")) 
                || (this.state.currentScenario === 4 && this.state.equation.includes("+"))) {
                    this.setState({possibleHint: "Have you consider which OR you are using? Can you do them at the same time?"})
                } 
            }
            if(wrongAmount === 1) {
                if(this.state.currentScenario === 4 && !this.state.equation.includes("`")) {
                    this.setState({possibleHint: "Have you included a NOT (`) to represent the door being not locked?"})
                }
            }

            this.setState({button: "Try Again", countWrong: wrongAmount, checkButton: "danger"})
            setTimeout(() => {
                this.setState({ button: "Check Answer", checkButton: "primary" });
            }, 2000);
        }
    }

    /**
     * Renders display of quiz 1
     * Checks if user has done tutorial 1, if not is redirected to let's learn
     * Displays the current scenario user is on thorugh buttons
     */
    render() {
        if(this.state.cookies.get('tutorial1') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <div className="content">
                            <Row>
                                <Col>
                                    <h3 style={{textAlign: "center"}}>Quiz 1: Scenarios</h3>
                                    <div className="scenario">
                                        <p>Press the buttons to change between the different scenarios. For each, try to make an equation to represent the scenario and then check if it is correct. You should drag-and-drop the Boolean variables and Boolean operators into the box below.</p>
                                    </div>
                                    <Button outline color={this.state.oneButton} onClick={() => this.changeScenario(1)}>Scenario 1</Button>
                                    <Button outline color={this.state.twoButton} onClick={() => this.changeScenario(2)}>Scenario 2</Button>
                                    <Button outline color={this.state.threeButton} onClick={() => this.changeScenario(3)}>Scenario 3</Button>
                                    <Button outline color={this.state.fourButton} onClick={() => this.changeScenario(4)}>Scenario 4</Button>
                                    <div className="scenario">
                                        <h4>{this.state.scenario}</h4>
                                        <h5>{this.state.scenarioInfo}</h5>
                                        <h5>{this.state.scenarioVariables}</h5>
                                        <h5>Construct an equation below to represent this.</h5>
                                    </div>
                                    <div className="drag_things_to_boxes">
                                        <div className="things_to_drag">
                                            <ComponentsScenario />
                                        </div>
                                        <div className="boxes">
                                            <Box targetKey="box" equation={this.state.equation} onChangeEquation={this.onChangeEquation.bind(this)} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b>{this.state.possibleHint}</b>
                                    <br/>
                                    <Button outline color={this.state.checkButton} onClick={() => this.checkAnswer()}>{this.state.button}</Button>
                                    { (this.state.scenariosDone || this.state.quizComplete) && <Link to='/letslearn'><Button>Menu</Button></Link> }
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>  
            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default Quiz1;