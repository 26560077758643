import React, { Component } from 'react'
import TestRules from '../../Tools/TestRulesSimplify/TestRules'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import {Redirect, Link} from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';

/**
 * Component handling logic and display for quiz 3.
 * 
 * @example
 * return (
 *  <Quiz3 cookies={this.props.cookies}/>
 * )
 */
class Quiz3 extends Component {
    /**
     * Constructor for Quiz1
     * @param {Cookies} props.cookies Cookies to set when quiz is done
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "A•0",
            currentAnswer: "0",
            showPage1: true,
            showPage2: false,
            easyButton: "primary",
            mediumButton: "primary",
            hardButton: "primary",
            equationListEasy: [],
            equationListMedium: [],
            equationListHard: [],
            appliedRulesEasy: [],
            appliedRulesMedium: [],
            appliedRulesHard: [],
            currentEquationList: [],
            currentRulesList: [],
            lastEquationEasy: "A•0",
            lastEquationMedium: "A+(A`•B)",
            lastEquationHard: "A•(B+(A•B`))",
            currentCheckEquation: "",
            checkEquationEasy: "",
            checkEquationMedium: "",
            checkEquationHard: "",
            difficulty: 1,
            difficultyDone: false,
            simplestFormText: "",
            cookies: props.cookies,
            button1: "warning",
            button2: "secondary",
            quizComplete: false,
            finishedText: ""
        }
        this.onChangeEquation = this.onChangeEquation.bind(this)
        this.onCorrect = this.onCorrect.bind(this)
        this.updateRulesAndEquations = this.updateRulesAndEquations.bind(this)
        this.lastEquationInputted = this.lastEquationInputted.bind(this)
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('quiz3') || this.state.cookies.get('code')) {
            this.setState({quizComplete: true})
        }
    }

    /**
     * Used to keep the equations and rules applied from RulesHelp component
     * @param {list} equationList Keeps all equation used with rules
     * @param {list} appliedRules Keeps all rules applied previously
     * @param {string} checkEquation Next equation to check if pupil got answer right
     */
    updateRulesAndEquations = (equationList, appliedRules, checkEquation) => {
        switch(this.state.difficulty) {
            case 1:
                this.setState({equationListEasy: equationList, appliedRulesEasy: appliedRules, 
                    currentEquationList: equationList, currentRulesList: appliedRules, 
                    checkEquationEasy: checkEquation, currentCheckEquation: checkEquation})
                break;
            case 2:
                this.setState({equationListMedium: equationList, appliedRulesMedium: appliedRules, 
                    currentEquationList: equationList, currentRulesList: appliedRules, 
                    checkEquationMedium: checkEquation, currentCheckEquation: checkEquation})
                break;
            case 3:
                this.setState({equationListHard: equationList, appliedRulesHard: appliedRules, 
                    currentEquationList: equationList, currentRulesList: appliedRules, 
                    checkEquationHard: checkEquation, currentCheckEquation: checkEquation})
                break;
            default:
                break;
        }
    }

    /**
     * Saves the last equation pupil put into drag and drop for that difficulty
     * @param {string} equation last equation inputted 
     */
    lastEquationInputted = (equation) => {
        switch(this.state.difficulty) {
            case 1:
                this.setState({equation: equation, lastEquationEasy: equation})
                break;
            case 2:
                this.setState({equation: equation, lastEquationMedium: equation})
                break;
            case 3:
                this.setState({equation: equation, lastEquationHard: equation})
                break;
            default:
                break;
        }
    }

    /**
     * Changes state equation binding to TestRules component
     * @param {string} newEquation the new equation state will be set to
     */
    onChangeEquation(newEquation) {
        this.setState({
            equation: newEquation
        });
    }
    
    /**
     * Quiz is split into two parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, button1: "warning", button2: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, button1: "secondary", button2: "warning"})
                break;
            default:
                break;
        }
    }

    /**
     * Changes the difficulty of the equation currently displaying for the quiz
     * @param {number} difficulty index of difficulty, 1 is easy, 2 medium, and 3 is hard
     */
    changeDifficult(difficulty) {
        switch(difficulty) {
            case 1:
                this.setState({equation: this.state.lastEquationEasy, display: false, currentAnswer: "0", difficulty: 1, 
                simplestFormText: "", currentEquationList: this.state.equationListEasy, 
                currentRulesList: this.state.appliedRulesEasy, currentCheckEquation: this.state.checkEquationEasy})
                break;
            case 2:
                this.setState({equation: this.state.lastEquationMedium, display: false, currentAnswer: "A+B", difficulty: 2, 
                simplestFormText: "", currentEquationList: this.state.equationListMedium, 
                currentRulesList: this.state.appliedRulesMedium, currentCheckEquation: this.state.checkEquationMedium})
                break;
            case 3:
                this.setState({equation: this.state.lastEquationHard, display: false, currentAnswer: "A", difficulty: 3, 
                simplestFormText: "", currentEquationList: this.state.equationListHard, 
                currentRulesList: this.state.appliedRulesHard, currentCheckEquation: this.state.checkEquationHard})
                break;
            default:
        }
    }

     /**
      * OnCorrect binds to TestRules, if pupils has got the correct answer then current difficulty is completed
      * @param {string} equation the current equation pupil is working on
      */
    onCorrect(equation) {
        if(equation === this.state.currentAnswer) {
            let easyButton = this.state.easyButton
            let mediumButton = this.state.mediumButton
            let hardButton = this.state.hardButton
            switch(this.state.difficulty) {
                case 1:
                    this.setState({easyButton: "success", simplestFormText: "You have completed this difficulty, the equation reached it's simplest form."})
                    easyButton = "success"
                    break;
                case 2:
                    this.setState({mediumButton: "success", simplestFormText: "You have completed this difficulty, the equation reached it's simplest form."})
                    mediumButton = "success"
                    break;
                case 3:
                    this.setState({hardButton: "success", simplestFormText: "You have completed this difficulty, the equation reached it's simplest form."})
                    hardButton = "success"
                    break;
                default:
                    break;
            }
            if(easyButton === "success" && mediumButton === "success" && hardButton === "success") {
                this.setState({quizComplete: true, finishedText: "You have completed everything, well done!"})
                this.state.cookies.set('quiz3','true', {path: '/'})
            }
        }
    }

    /**
     * Renders display of quiz 3
     * Checks if user has done tutorial 4, if not is redirected to let's learn
     * Uses Test Rules tool to display 3 different equations for pupils to simplify for the quiz
     */
    render() {
        if(this.state.cookies.get('tutorial4') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <Row>
                            <Col>
                                {this.state.showPage1 && <div className="content">
                                    <h3>Quiz 3: Boolean Algebra</h3>
                                        <div className="scenario">  
                                            <p>Now you have had a bit of practice, let's put you to the test.</p>
                                            <p>On the next page, you will have 3 equations to simplfiy, all at different difficulties.</p>
                                            <p>This is the same process as tutorial 4, except this time the app won't give you the answers.</p>
                                            <p>Once you press a rule, you'll have to input what you think the answer is.</p>
                                        </div>
                                </div> }

                                {this.state.showPage2 && <div>
                                    <div className="content">
                                        <p>When you have the equation in the simplest form the difficulty button will turn green.</p>
                                        <Button outline color={this.state.easyButton} onClick={() => this.changeDifficult(1)}>Easy</Button>
                                        <Button outline color={this.state.mediumButton} onClick={() => this.changeDifficult(2)}>Medium</Button>
                                        <Button outline color={this.state.hardButton} onClick={() => this.changeDifficult(3)}>Hard</Button>
                                        <br/>
                                        <h5>{this.state.simplestFormText}</h5>
                                    </div>
                                    <TestRules editable={false} correct={false} equation={this.state.equation} 
                                    equationList={this.state.currentEquationList} appliedRules={this.state.currentRulesList} 
                                    onChangeEquation={this.onChangeEquation} onCorrect={this.onCorrect} 
                                    updateRulesAndEquations={this.updateRulesAndEquations} lastEquationInputted={this.lastEquationInputted}
                                    currentCheckEquation={this.state.currentCheckEquation} finalEquation={this.state.currentAnswer}/>
                                </div> }
                            </Col>
                    </Row>

                    <div className="content">
                        <Row>
                            <Col>
                                <h4>{this.state.finishedText}</h4>
                                {this.state.quizComplete && 
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>                 
                                        </ButtonGroup>
                                    <Link to='/letslearn'><Button>Menu</Button></Link>
                                    </ButtonToolbar>}

                                {!this.state.quizComplete && !this.state.showPage1 &&
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                        </ButtonGroup>
                                    </ButtonToolbar>}
                                {!this.state.quizComplete && this.state.showPage1 && <Button onClick={() => this.changePage(2)}>Next</Button>}
                            </Col>
                        </Row>
                    </div>
                </Container>
                </div>  

            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default Quiz3;