import React, { Component } from 'react'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap'
import {Redirect, Link} from 'react-router-dom'
import RulesHelp from '../../../Tools/RuleSimplify/RulesHelp'
import { Container, Row, Col } from 'reactstrap';

/**
 * Component handling logic and display for Tutorial 4.
 * 
 * @example
 * return (
 *  <FourTutorial cookies={this.props.cookies}/>
 * )
 */
class FourTutorial extends Component {
    /**
     * Constructor for Tutorial4
     * @param {Cookies} props.cookies Cookies to set when tutorial is done
     */
    constructor(props) {
        super(props);
        this.state = {
            showPage1: true,
            showPage2: false,
            tutorialComplete: false,
            cookies: props.cookies,
            button1: "warning",
            button2: "secondary",
            equation: "B+1",
            equationListEasy: [],
            equationListMedium: [],
            equationListHard: [],
            appliedRulesEasy: [],
            appliedRulesMedium: [],
            appliedRulesHard: [],
            currentEquationList: [],
            currentRulesList: [],
            currentAnswer: "1",
            easyButton: "primary",
            mediumButton: "primary",
            hardButton: "primary",
            currentDifficulty: 1,
            doneText: ""
        }
        this.updateRulesAndEquations = this.updateRulesAndEquations.bind(this)
        this.toggleAnswer = this.toggleAnswer.bind(this)
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('tutorial4') || this.state.cookies.get('code')) {
            this.setState({tutorialComplete: true})
        }
    }


    /**
     * Tutorial is split into two parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, button1: "warning", button2: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, button1: "secondary", button2: "warning"})
                break;
            default:
                break;
        }
    }

    /**
     * Used to keep the equations and rules applied from RulesHelp component
     * @param {list} equationList Keeps all equation used with rules
     * @param {list} appliedRules Keeps all rules applied previously
     */
    updateRulesAndEquations = (equationList, appliedRules) => {
        switch(this.state.currentDifficulty) {
            case 1:
                this.setState({equationListEasy: equationList, appliedRulesEasy: appliedRules, currentEquationList: equationList, currentRulesList: appliedRules})
                break;
            case 2:
                this.setState({equationListMedium: equationList, appliedRulesMedium: appliedRules, currentEquationList: equationList, currentRulesList: appliedRules})
                break;
            case 3:
                this.setState({equationListHard: equationList, appliedRulesHard: appliedRules, currentEquationList: equationList, currentRulesList: appliedRules})
                break;
            default:
                break;
        }
    }

    /**
     * Changes the difficulty of the equation for the pupil to simplify
     * @param {number} difficulty sets difficulty of current equation displayed
     */
    changeDifficulty(difficulty) {
        switch(difficulty) {
            case 1:
                this.setState({equation: "B+1", display: false, currentAnswer: "1", answer: "Check Answer", checkButton: "primary",
                currentDifficulty: 1, currentEquationList: this.state.equationListEasy, currentRulesList: this.state.appliedRulesEasy, doneText: ""})
                break;
            case 2:
                this.setState({equation: "A•(0+A)", display: false, currentAnswer: "A", answer: "Check Answer", checkButton: "primary",
                currentDifficulty: 2, currentEquationList: this.state.equationListMedium, currentRulesList: this.state.appliedRulesMedium, doneText: ""})
                break;
            case 3:
                this.setState({equation: "(A•(A+B))+(B•(A+B))", display: false, currentAnswer: "A+B", answer: "Check Answer", checkButton: "primary",
                currentDifficulty: 3, currentEquationList: this.state.equationListHard, currentRulesList: this.state.appliedRulesHard, doneText: ""})
                break;
            default:
                break;
        }
    }

    /**
     * Called when correct answer is reached within the rules tool
     * @param {string} equation the last equation user reached within the tool
     */
    toggleAnswer(equation) {
        if(equation === this.state.currentAnswer) {
            let easyButton = this.state.easyButton
            let mediumButton = this.state.mediumButton
            let hardButton = this.state.hardButton
            switch(this.state.currentDifficulty) {
                case 1: 
                    this.setState({easyButton: "success", doneText: "Well done, you have completed this difficulty."})
                    easyButton = "success"
                    break;
                case 2:
                    this.setState({mediumButton: "success", doneText: "Well done, you have completed this difficulty."})
                    mediumButton = "success"
                    break;
                case 3:
                    this.setState({hardButton: "success", doneText: "Well done, you have completed this difficulty."})
                    hardButton = "success"
                    break;
                default:
                    break;
            }
            if(easyButton === "success" && mediumButton === "success" && hardButton === "success") {
                this.setState({tutorialComplete: true})
                this.state.cookies.set('tutorial4','true', {path: '/'})
            }
        }
    }


     /**
     * Renders display of tutorial 4, displays all the parts with navigation at the bottom
     */
    render() {
        if(this.state.cookies.get('tutorial3') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <Row>
                            <Col>
                                {this.state.showPage1 && 
                                <div className="content">
                                    <h3>Boolean Algebra</h3>
                                    <div className="scenario">  
                                        <p>Now having become familar with some of the rules, time to simplfy some equations.</p>
                                        <p>In the next part, we will give you some equations, you need to pick the rules you think will simplify them.</p>
                                        <p>The app will give you the answer when a rule is applied, try every difficulty.</p>
                                    </div>
                                </div>}

                                { this.state.showPage2 &&
                                <div>
                                    <div className="content">
                                        <p>When you have the equation in the simplest form the difficulty button will turn green.</p>
                                        <Button outline color={this.state.easyButton} onClick={() => this.changeDifficulty(1)}>Easy</Button>
                                        <Button outline color={this.state.mediumButton} onClick={() => this.changeDifficulty(2)}>Medium</Button>
                                        <Button outline color={this.state.hardButton} onClick={() => this.changeDifficulty(3)}>Hard</Button>
                                        <h5>{this.state.doneText}</h5>
                                    </div>
                                    <RulesHelp equation={this.state.equation} editable={false} equationList={this.state.currentEquationList} 
                                    appliedRules={this.state.currentRulesList} updateRulesAndEquations={this.updateRulesAndEquations}
                                    finalAnswer={this.state.currentAnswer} toggleAnswer={this.toggleAnswer}/>
                                </div>}
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                {this.state.tutorialComplete && 
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>                 
                                        </ButtonGroup>
                                    <Link to='/letslearn'><Button>Menu</Button></Link>
                                    </ButtonToolbar>}

                                <div className="content">
                                    { !this.state.tutorialComplete && !this.state.showPage1 &&
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                        </ButtonGroup>
                                    </ButtonToolbar>}
                                    { !this.state.tutorialComplete && this.state.showPage1 && <Button onClick={() => this.changePage(2)}>Next</Button>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>  
            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default FourTutorial;