import React, { Component } from 'react'
import EquationTruth from '../../../Tools/ExpressionBuilder/EquationTruth'
import { Button } from 'reactstrap';
import firstImage from '../../../assets/images/beach.jpg'
import secondImage from '../../../assets/images/mobile.png'
import thirdImage from '../../../assets/images/tea.jpg'
import fourthImage from '../../../assets/images/buttonOff.png'

/**
 * Component for displaying part 2 in tutorial 2.
 * 
 * @example
 * return (
 *  <TwoPart2 onComplete={this.onComplete2}/>
 * )
 */
class TwoPart2 extends Component {
    /**
     * Constructor for Part2
     * @param {Function} props.onComplete Called when this part of tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "C•S",
            variable: "Variable C represents there being no class, and S represents it being sunny outside.",
            row1: "We have class and it is not sunny outside, we can't go to the beach.",
            row2: "We have class and it is sunny outside, we can't go to the beach.",
            row3: "We don't have class but it is not sunny outside, we can't go the beach.",
            row4: "We don't have class and it is sunny outside, let's go to the beach!",
            image: firstImage,
            orButton: "primary",
            exclusiveButton: "primary",
            notButton: "primary",
            twoButton: "primary",
            threeButton: "primary"
        }
    }

    /**
     * Changes the equation for the corresponding operator and the scenario information (rows)
     * @param {string} operator name of the operator that will display 
     */
    changeToOperator(operator) {
        switch(operator) {
            case "AND":
                this.setState({
                    equation: "C•S",
                    variable: "Variable C represents there being no class, and S represents it being sunny outside.",
                    row1: "We have class and it is not sunny outside, we can't go to the beach.",
                    row2: "We have class and it is sunny outside, we can't go to the beach.",
                    row3: "We don't have class but it is not sunny outside, we can't go the beach.",
                    row4: "We don't have class and it is sunny outside, let's go to the beach!",
                    image: firstImage
                });
              break;
            case "OR":
                this.setState({
                    equation: "B+C",
                    variable: "Variable B represents phone having battery, and C represents the phone being on charge.",
                    row1: "Phone has no battery and is not on charge, we cannot use it.",
                    row2: "Phone has no battery but is on charge, we can use it.",
                    row3: "Phone has battery and is not on charge, we can use it.",
                    row4: "Phone has battery and is on charge, we can use it.",
                    orButton: "success",
                    image: secondImage
                });
                if (this.state.exclusiveButton === "success" && this.state.notButton === "success") {
                    this.props.onComplete()
                }
              break;
            case "EXCLUSIVE":
                this.setState({
                    equation: "C⊕T",
                    variable: "Variable C represents asking for coffee, and T represents asking for tea.",
                    row1: "We did not ask for coffee and we did not ask for tea, we get nothing.",
                    row2: "We did not ask for coffee but we asked for tea, we get tea.",
                    row3: "We asked for coffee and did not ask for tea, we get coffee.",
                    row4: "We asked for coffee and tea, we cannot be greedy, we get nothing.",
                    exclusiveButton: "success",
                    image: thirdImage
                });
                if (this.state.orButton === "success" && this.state.notButton === "success") {
                    this.props.onComplete()
                }
                break;
            case "NOT":
                this.setState({
                    equation: "S`",
                    variable: "Variable S represents the light switch.",
                    row1: "The light switch is off, therefore the light is on.",
                    row2: "The light switch is on, therefore the light is off.",
                    row3: "",
                    row4: "",
                    notButton: "success",
                    image: fourthImage
                });
                if (this.state.orButton === "success" && this.state.exclusiveButton === "success") {
                    this.props.onComplete()
                }
                break;
            default:
                break;
        }
    }

    /**
     * Displays all the Boolean operators within truth tables to switch between
     */
    render() {
        return (
            <div>
                <p>Press the buttons to see what the Truth Table would look like for each operator.</p>
                <Button outline color="success" onClick={() => this.changeToOperator("AND")}>AND</Button>
                <Button outline color={this.state.orButton} onClick={() => this.changeToOperator("OR")}>OR</Button>
                <Button outline color={this.state.exclusiveButton} onClick={() => this.changeToOperator("EXCLUSIVE")}>EXCLUSIVE OR</Button>
                <Button outline color={this.state.notButton} onClick={() => this.changeToOperator("NOT")}>NOT</Button>
                <div className="scenario">
                    <EquationTruth equation={this.state.equation} editable={false}/>
                </div>
                <h3>Explained Within Scenario</h3>
                <div style={{display: "inline-block", maxWidth: "400px"}}> 
                    <img style={{maxWidth: "100%", height: "auto", width: "auto", objectFit: "contain"}} src={this.state.image} alt="Scenario" align="middle" />
                </div>
                <div className="scenario">
                    <p>Meaning of each row in relation to their scenario:</p>
                    <p>{this.state.variable}</p>
                    <p><b>Row 1: </b> {this.state.row1}</p>
                    <p><b>Row 2: </b> {this.state.row2}</p>
                    {this.state.row3 &&<p><b>Row 3: </b> {this.state.row3}</p>}
                    {this.state.row4 &&<p><b>Row 4: </b> {this.state.row4}</p>}
                </div>
            </div> 
        )
    }
}

export default TwoPart2;