import React, { Component } from 'react';
import { DragDropContainer } from 'react-drag-drop-container';

/**
 * Component Boxable is an item you can drop.
 * 
 * @example
 * return (
 *  <Boxable targetKey="box" label="A" colour="blue" error="" />
 * )
 */
export default class Boxable extends Component {
  /**
   * Renders Box component that can be dragged
   */
  render() {
    return (
      <div className="boxable_component" style={{ display: 'inline-block' }}>
        <DragDropContainer
          targetKey={this.props.targetKey}
          dragData={{ label: this.props.label, colour: this.props.colour }}
          customDragElement={this.props.customDragElement}>

          <div className="boxable_component" style={{ padding: 5 }}>
            <h4>{this.props.label} </h4>
          </div>

        </DragDropContainer>
      </div>
    );
  }
}