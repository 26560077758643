import React, { Component } from 'react'
import TestRules from './TestRules'

/**
 * Component that displays a page that can use TestRules Component.
 * 
 * @example
 * return (
 *   <TeachRules/>
 * )
 */
class TeachRules extends Component {
    /**
     * Renders TestRules Component
     */
    render() {
        return (
            <div className="inner-container">
                <div style={{ textAlign: "center" }}>
                    <h4>Input a valid equation and apply rules you can find!</h4>
                    <h5>Test yourself, put in what you think the answer should be</h5>
                </div>
                <TestRules/>
            </div>
        );
    }
}

export default TeachRules;