/**
 * Holds information for a Node within a Tree
 */
class Node {
    /**
     * Constructor for Node
     * @param {string} data string/data stored inside Node
     */
    constructor(data) {
        this.children = [];
        this.data = data;
        this.column = []; 
    }

    /**
     * Sets the column within the Node for truth tables
     * @param {list} list list to set the column to
     */
    setColumn(list) {
        this.column = list
    }

    /**
     * Return tree if column is empty
     */
    isColumnEmpty() {
        return this.column === undefined || this.column.length === 0
    }

    /**
     * Recursively called to find leaves within the node
     * @param {Node} node node that is passed in then find leaves
     * @param {*} list list of leaves found
     */
    findLeaves(node, list) {
        if(node === undefined) {
            return list;
        }
        if(node.checkNodeLeaf()) {
            list.push(node)
        }
        this.findLeaves(node.left(), list)
        this.findLeaves(node.right(), list)
        return list
        
    }
    
    /**
     * Switch the left and right child around
     */
    switchChildren() {
        let left = this.children[0]
        let right = this.children[1]
        this.children[0] = right
        this.children[1] = left
    }

    /**
     * Gets the left child (index 0)
     */
    left() {
        return this.children[0]
    }

    /**
     * Gets the right child (index 1)
     */
    right() {
        return this.children[1]
    }

    /**
     * if value is in child 0 (left) return index 1 (right) and vice versa
     * @param {string} value value contained within node 
     */
    getOtherIndex(value) {
        if (this.children[0].data === value) {
            return 1
        } else if(this.children[1].data === value) {
            return 0
        }
    }

    /**
     * return true if one of the child is operator leaf and other is variable
     * Used for Distributive law
     * @param {string} operator value within operator
     */
    operatorOneChildDistributive(operator) {
        let variables = ["A", "B", "C", "D", "F", "H", "C", "R", "L","1","0","`"]
        if (this.children[0].data === operator && this.children[0].children[0].checkNodeLeaf() && this.children[0].children[1].checkNodeLeaf() && variables.includes(this.children[1].data)) {
            return true
        }
        if (this.children[1].data === operator && this.children[1].children[0].checkNodeLeaf() && this.children[1].children[1].checkNodeLeaf() && variables.includes(this.children[0].data)) {
            return true
        }
        return false
    }

    /**
     * return true if value is in both left and right child are operators with leaves
     * @param {string} operator value within operator
     */
    operatorBothChild(operator) {
        if (this.children[0].data === operator && this.children[1].data === operator
            && this.children[0].children[0].checkNodeLeafOrNotWithLeaf() && this.children[0].children[1].checkNodeLeafOrNotWithLeaf()
            && this.children[1].children[0].checkNodeLeafOrNotWithLeaf() && this.children[1].children[1].checkNodeLeafOrNotWithLeaf()) {
            return true
        }
        return false
    }

    /**
     * used for the Absorption law
     * if a child is not operator and other is a leaf, and not operator child is leaf return true
     */
    absorptionNotAndLeaves() {
        if(this.children[0].data === '`' && this.children[1].checkNodeLeaf() && this.children[0].children[0].checkNodeLeaf()) {
            return true
        } if(this.children[1].data === '`' && this.children[0].checkNodeLeaf() && this.children[1].children[0].checkNodeLeaf()) {
            return true
        }
        return false
    }

    /**
     * Change children to the passed in values
     * @param {Node} leftNode left child to change to
     * @param {Node} rightNode right child to change to
     */
    changeChildren(leftNode, rightNode) {
        this.children[0] = leftNode
        this.children[1] = rightNode
    }

    /**
     * Set completely new children
     * @param {string} leftValue left child to change data to
     * @param {string} rightValue right child to change data to
     */
    newChildrenValues(leftValue, rightValue) {
        this.children[0] = new Node(leftValue)
        this.children[1] = new Node(rightValue)
    }

    /**
     * Changes the left child (index 0) of the Node
     * @param {Node} node node to change left child to
     */
    changeLeftChild(node) {
        this.children[0] = node
    }

    /**
     * Changes the right child (index 1) of the Node
     * @param {Node} node node to change right child to
     */
    changeRightChild(node) {
        this.children[1] = node 
    }

    /**
     * if left value equals to value passed in return true
     * @param {string} value value of node that is wanted
     */
    leftValue(value) {
        if (this.children[0].data === value) {
            return true
        }
        return false
    }

    /**
     * if right value equals to value passed in return true
     * @param {string} value value of node that is wanted
     */
    rightValue(value) {
        if (this.children[1].data === value) {
            return true
        }
        return false
    }

    /**
     * Return true if left child (index 0) is a NOT Node
     */
    checkLeftNotNode() {
        if (this.children[0].data === '`') {
            return true
        }
        return false
    }

    /**
     * Return true if right child (index 1) is a NOT Node
     */
    checkRightNotNode() {
        if (this.children[1].data === '`') {
            return true
        }
        return false
    }

    /**
     * Change data of current node and set children
     * @param {string} data data that current node will be set to
     * @param {string} leftChild value that left child will be set to
     * @param {string} rightChild value that right child will be set to
     */
    changeDataAndChildren(data, leftChild, rightChild) {
        this.data = data
        this.children[0] = new Node(leftChild)
        this.children[1] = new Node(rightChild)
    }

    /**
     * Change data of current node and set children
     * @param {string} data data that current node will be set to
     * @param {string} leftChild node that left child will be set to
     * @param {string} rightChild node that right child will be set to
     */
    changeDataAndNodes(data, leftChild, rightChild) {
        this.data = data
        this.children[0] = leftChild
        this.children[1] = rightChild
    }

    /**
     * Replace the current Node with a different one
     * @param {Node} node node to replace current node with
     */
    replaceNode(node) {
        this.data = node.data
        this.children = node.children
    }

    /**
     * if double negation child will become child below that
     * @param {string} value value that current node data is set to
     * @param {Node} node node that will be used for its children
     */
    changeDataNegate(value, node) {
        this.data = value
        this.children = node.children[0].children
    }

    /**
     * sets node data and removes children
     * @param {string} value value that node data will be set to
     */
    changeValueNoChildren(value) {
        this.data = value
        this.children = []
    }

    /**
     * Checks if children hold the same value
     * @param {string} value value that want nodes to match
     */
    checkChildrenSame(value) {
        if (this.children[0].data === value && this.children[1].data === value) {
            return true
        }
        return false
    }

    /**
     * Used for the Inverse law
     * @param {string} value value for node that want children to be
     */
    checkInverse(value) {
        if (this.children[0].data === value && this.children[1].data === "`" && this.children[1].children[0].data === value) {
            return true
        }
        if (this.children[1].data === value && this.children[0].data === "`" && this.children[0].children[0].data === value) {
            return true
        }
        return false
    }

    /**
     * return true if value is at the index of children
     * @param {number} index index of child
     * @param {string} value value want child to be
     */
    checkSpecificChild(index, value) {
        if (this.children[index].data === value) {
            return true
        }
        return false
    }

    /**
     * Add a child to the Node
     * @param {Node} child Node to add as a child
     */
    addChild(child) {
        this.children.push(child);
    }

    /**
     * Recursively used in Tree to return all Nodes
     * @param {list} list list of the nodes so far
     */
    nodePrint(list) {
        list.push(this.data)
        let length = this.children.length;
        for (let i = 0; i < length; i++) {
            list = this.children[i].nodePrint(list);
        }
        return list;
    }

    /**
     * Recursively used in Tree to return all Nodes
     * @param {list} nodes list of the nodes so far
     */
    nodeWalk(nodes) {
        let nodeList = []
        nodeList.push.apply(nodeList, nodes);
        nodeList.push(this);

        try {
            return this.children[0].nodeWalk(nodeList);
        } catch (e) {
            return nodeList;
        }
    }

    /**
     * return the child that is a variable or not
     */
    returnChildrenVariable() {
        let variables = ["A", "B", "C", "D", "F", "H", "C", "R", "L", "1","0","`"]
        if(variables.includes(this.children[0].data)) {
            return this.children[0]
        }
        if(variables.includes(this.children[1].data)) {
            return this.children[1]
        }  
    }

    /**
     * return true if both children are leaves
     */
    checkChildrenLeaves() {
        if(this.children[0].children.length === 0 && this.children[1].children.length === 0) {
            return true
        }
        return false
    }

    /**
     * Return true if Node is a leaf (has no children)
     */
    checkNodeLeaf() {
        return this.children.length === 0
    }

    /**
     * If value is not returns child data value or just returns data value
     */
    returnValueOrNotChildValue() {
        if(this.data === "`") {
            return this.children[0].data
        } else {
            return this.data
        }
    }

    addNotToChildIfPresent() {
        if(this.data === "`") {
            return this.children[0].data + '`'
        } else {
            return this.data
        } 
    }

    /**
     * Return true if Node is a leaf (has no children)
     * or is a NOT Node with a leaf
     */
    checkNodeLeafOrNotWithLeaf() {
        if(this.children.length === 0) {
            return true
        } else if(this.data === '`' && this.children[0].children.length === 0) {
            return true
        } else {
            return false
        }
    }

    /**
     * Return the amount of children Node has
     */
    childrenCount() {
        return this.children.length;
    }

    /**
     * Get the data of the current Node
     */
    getData() {
        return this.data
    }
}

export default Node;