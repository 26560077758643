import React, { Component } from 'react'
import Box from '../DragDrop/Box'
import Components from '../DragDrop/Components'
import RulesTable from './RulesTable'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import FindRules from '../TreeParser/RulesTree/FindRulesTree'
import ApplyRules from '../TreeParser/RulesTree/ApplyRulesTree'
import Generate from '../TreeParser/Generate'
import Tree from '../TreeParser/Tree'
import '../../assets/css/Rules.css'

/**
 * Component that simplfies equations itself using Boolean identities, App gives the pupil the answer
 * 
 * @example
 * return (
 *  <RulesHelp/>
 *  <RulesHelp equation={this.state.equation} editable={false} equationList={this.state.currentEquationList} 
 * )
 */
class RulesHelp extends Component {
  /**
   * Constructor for RulesHelp
   * @param {string} props.equation Equation to be displayed within Box
   * @param {boolean} props.editable When True components can be dragged
   */
  constructor(props) {
    super(props);
    this.state = {
      equation: "",
      lastEquation: "",
      equationList: [],
      appliedRules: [],
      tree: new Tree(),
      correct: false,
      rules: [],
      title: "",
      width: window.innerWidth,
      popoverOpen: false,
      visible: true,
      editable: true
    }
    this.toggle = this.toggle.bind(this);
  }

  /**
   * Handles setting initial state when component is mounted
   */
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    if(this.props.equation) {
      this.onChangeEquation(this.props.equation, true)
    }
    if(this.props.editable === false) {
      this.setState({editable: false})
    }
    if(this.props.equationList && this.props.equationList.length !== 0) {
      this.setState({equationList: this.props.equationList, visible: false})
    }
    if(this.props.appliedRules && this.props.appliedRules.length !== 0) {
      this.setState({appliedRules: this.props.appliedRules, visible: false})
    }
  }

    /**
     * Handles when component updates
     * @param {*} prevProps previous properties of the component
     */
    componentDidUpdate(prevProps) {
      if(this.props.equation && this.props.equation !== prevProps.equation) {
          this.setState({equation: this.props.equation, equationList: this.props.equationList, appliedRules: this.props.appliedRules})
          this.onChangeEquation(this.props.equation, true)
          if (this.props.equationList.length === 0 && this.props.appliedRules.length === 0) {
            this.setState({visible: true})
          } else {
            this.setState({visible: false})
            let newEquation = this.props.equationList[this.props.equationList.length - 1]
            let tree = new Tree(new Generate(newEquation).evaluate(true))
            let rules = new FindRules(tree)
            this.setState({ tree: tree, rules: rules.evaluate(), title: rules.getTitle() })
            if(newEquation === this.props.finalAnswer) {
              this.setState({rules: [], title: []})
            }
          }
      } 
  }

  /**
   * Removes event listened when component unmounted
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  /**
   * Handles when the window size changes
   */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Toggles displays the rules table popover
   */
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  /**
   * Handles the equation changing and is bound to Box
   * @param {string} newEquation the new equation the box is set to
   * @param {boolean} correct whether the equation is correct or not
   */
  onChangeEquation(newEquation, correct) {
    let tree = new Tree(new Generate(newEquation).evaluate(correct))
    this.setState({
      equation: newEquation,
      correct: correct,
      tree: tree
    });
    if (correct === true && newEquation !== "") {
      let rules = new FindRules(tree)
      this.setState({ equation: newEquation, rules: rules.evaluate(), title: rules.getTitle() })
    }
  }
  
  /**
   * Handles applying the rule that the pupil clicked on to the current equation
   * Also finds new rules once law is applied
   * @param {string} law name of the law that is being applied
   */
  apply(law) {
    let tree = new ApplyRules(law, this.state.tree).evaluate()
    let next = this.state.equationList
    let applied = this.state.appliedRules
    applied.push(law)
    let newEquation = tree.printOrder()
    next.push(newEquation)

    if (this.props.equationList) {
      this.props.updateRulesAndEquations(next, applied)
    }

    if(newEquation === this.props.finalAnswer) {
      this.props.toggleAnswer(newEquation)
      this.setState({ equationList: next, tree: tree, appliedRules: applied, rules: [], title: [], visible: false })
    } else {
      let rules = new FindRules(tree)
      this.setState({ equationList: next, tree: tree, appliedRules: applied, rules: rules.evaluate(), title: rules.getTitle(), visible: false })
    }
  }

  /**
   * Resets and deletes the current equation
   */
  reset() {
    this.setState({ equationList: [], tree: new Tree(), appliedRules: [], rules: [], title: "", visible: true, equation: "", lastEquation: "" })
    if(this.props.equation && this.props.equation !== "") {
      this.setState({equation: this.props.equation})
      this.onChangeEquation(this.props.equation, true)
    }
    if (this.props.equationList) {
      this.props.updateRulesAndEquations([], [])
    }
  }

  /**
   * Displays the box with the equation, then buttons with rules that can simplify the equation
   */
  render() {
    const { width } = this.state;
    const isMobile = width <= 480;
    return (
      <div>
        {!this.state.visible && <div className="rulesApplied"> <h4>Original Equation: </h4> <h2>{this.state.equation}</h2> </div>}
        {this.state.appliedRules.map((item, key) => {
          return (
            <div className="rulesApplied" key={key}>
              <h4>{item} Applied</h4>
              <h2>{this.state.equationList[key]}</h2>
            </div>
          )
        })}
        {this.state.visible &&
          <div className="drag_things_to_boxes">
            <div className="things_to_drag">
              {this.state.editable && <Components />}
            </div>
            <div className="boxes">
              <Box editable={this.state.editable} targetKey="box" equation={this.state.equation} onChangeEquation={this.onChangeEquation.bind(this)} />
            </div>
          </div>}
        <div>
          {!this.state.visible && <div><Button style={{ float: 'right'}} outline color="danger" onClick={() => this.reset()}>Reset</Button></div>}
          {!isMobile &&
            <div style={{ float: "right"}}>
              <Button id="PopoverLegacy" type="button">Rules Table</Button>
              <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy">
                <PopoverHeader>Rules</PopoverHeader>
                <PopoverBody><RulesTable /></PopoverBody>
              </UncontrolledPopover>
            </div>}
        </div>
        <h5>{this.state.correct && this.state.title}</h5>
        {this.state.correct && this.state.rules.map((item, key) => {
          return (
            <span key={key}><Button outline color="primary" onClick={() => this.apply(item)}>{item}</Button></span>
          )
        })}
        {isMobile && <RulesTable />}
      </div>
    );
  }
}

export default RulesHelp;