/**
 * Class that can find each Boolean identity to a equation Tree.
 */
class FindRulesTree {
    /**
     * Constructor for FindRulesTree
     * @param {Tree} tree Tree of current equation
     */
    constructor(tree) {
        this.tree = tree
        this.title = "Laws that can be performed:"
        this.variables = ["A", "B", "C", "1", "0"]
    }

    /**
     * Gets the title of text to display
     * Says if laws can be performed or not
     */
    getTitle() {
        return this.title;
    }

    /**
     * Used for both AND & OR Commutative law
     * If there is any AND/OR Nodes law can be performed
     * @param {list} andNodes all AND Nodes within the Tree
     * @param {list} orNodes all NOT Nodes within the Tree
     */
    commutativeLaw(andNodes, orNodes) {
        let rules = []
        if (andNodes.length > 0) {
            rules.push("AND Form: Commutative Law")
        }
        if (orNodes.length > 0) {
            rules.push("OR Form: Commutative Law")
        }
        return rules
    }

    /**
     * Used for both AND & OR Associate law
     * Passes them both to base Associate
     * @param {list} andNodes all AND Nodes within the Tree
     * @param {list} orNodes all NOT Nodes within the Tree
     */
    associateLaw(andNodes, orNodes) {
        let rules = []
        rules.push.apply(rules, this.baseAssociate(andNodes, "AND", "•"))
        rules.push.apply(rules, this.baseAssociate(orNodes, "OR", "+"))
        return rules
    }

    /**
     * Base Associate used by both AND, OR Associate law
     * if one of the children is the same operator, can be applied
     * @param {list} nodes all nodes relevent to type of law being applied
     * @param {string} type the word for the type of law
     * @param {string} value the symbol for the operator being applied
     */
    baseAssociate(nodes, type, value) {
        let rules = []
        for(let i = 0; i < nodes.length; i++) {
            if(nodes[i].leftValue(value) || nodes[i].rightValue(value)) {
                rules.push(type + " Form: Associate Law")
            }
        }
        return rules 
    }

    /**
     * Used for AND, OR Distributive law
     * Makes use of base Distributive
     * @param {list} andNodes all of the AND Nodes found within the Tree
     * @param {list} orNodes all of the OR Nodes found within the Tree
     */
    distributiveLaw(andNodes, orNodes) {
        let rules = []
        rules.push.apply(rules, this.baseDistributive("OR", andNodes, "+"))
        rules.push.apply(rules, this.baseDistributive("AND", orNodes, "•"))
        rules.push.apply(rules, this.baseDistributiveReverse("OR", orNodes, "•"))
        rules.push.apply(rules, this.baseDistributiveReverse("AND", andNodes, "+"))
        return rules
    }

    /**
     * Used for the base of the Distributive law
     * @param {string} type the word for the type of law being applied
     * @param {list} nodes all Nodes relevent to type of law being applied
     * @param {string} secondOperator symbol of the other operator involved in Law
     */
    baseDistributive(type, nodes, secondOperator) {
        let rules = []
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].operatorOneChildDistributive(secondOperator)) {
                rules.push(type + " Form: Distributive Law")
            } 
        }
        return rules
    }

    /**
     * Used for the base of the Distributive law reversed
     * @param {string} type the word for the type of law being applied
     * @param {list} nodes all Nodes relevent to type of law being applied
     * @param {string} secondOperator symbol of the other operator involved in Law
     */
    baseDistributiveReverse(type, nodes, secondOperator) {
        let rules = []
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].operatorBothChild(secondOperator)) {
                let BracketLeftValueLeft = nodes[i].left().left().addNotToChildIfPresent()
                let BracketLeftValueRight = nodes[i].left().right().addNotToChildIfPresent()

                if(BracketLeftValueLeft === nodes[i].right().left().addNotToChildIfPresent() || BracketLeftValueRight === nodes[i].right().left().addNotToChildIfPresent()
                || BracketLeftValueLeft === nodes[i].right().right().addNotToChildIfPresent() || BracketLeftValueRight === nodes[i].right().right().addNotToChildIfPresent()) {
                    rules.push(type + " Form: Distributive Law")
                }
            }
        }
        return rules        
    }

    /**
     * Used for AND, OR Identity law
     * Makes use of base Identity & Zero
     */
    identityLaw() {
        return this.baseIdentityZeroLaw(["1", "0"], "Identity")
    }

    /**
     * Used for AND, OR Zero & One law
     * Makes use of base Identity & Zero
     */
    ZeroAndOneLaw() {
        return this.baseIdentityZeroLaw(["0", "1"], "Zero and One")
    }

    /**
     * Used for the base of identity and zero & one law
     * @param {list} search values that want to find relevent to the law 
     * @param {string} law the name of the law being used
     */
    baseIdentityZeroLaw(search, law) {
        let andNodes = this.tree.findNodesOfType(["•"])
        let orNodes = this.tree.findNodesOfType(["+"])
        let rules = []
        for (let i = 0; i < andNodes.length; i++) {
            if (andNodes[i].children[0].data === search[0] || andNodes[i].children[1].data === search[0]) {
                rules.push("AND Form: " + law + " Law")
            }
        }
        for (let i = 0; i < orNodes.length; i++) {
            if (orNodes[i].children[0].data === search[1] || orNodes[i].children[1].data === search[1]) {
                rules.push("OR Form: " + law + " Law")
            }
        }
        return rules
    }

    /**
     * Used for AND, OR Inverse law
     * checks if children are same variables and one has negation
     * @param {list} andNodes all AND Nodes found within the Tree
     * @param {list} orNodes all OR Nodes found within the tree
     */
    InverseLaw(andNodes, orNodes) {
        let variables = ["A", "B", "C"]
        let rules = []
        for (let i = 0; i < variables.length; i++) {
            for (let j = 0; j < andNodes.length; j++) {
                if (andNodes[j].checkInverse(variables[i])) {
                    rules.push("AND Form: Inverse Law")
                }
            }
            for (let j = 0; j < orNodes.length; j++) {
                if (orNodes[j].checkInverse(variables[i])) {
                    rules.push("OR Form: Inverse Law")
                }
            }
        }
        return rules
    }

    /**
     * Used for AND, OR Idempotent law
     * Checks if the children are the same and are variables
     * @param {list} andNodes all AND Nodes found within the Tree
     * @param {list} orNodes all OR Nodes found within the tree
     */
    IdempotentLaw(andNodes, orNodes) {
        let variables = ["A", "B", "C"]
        let rules = []
        for (let i = 0; i < variables.length; i++) {
            for (let j = 0; j < andNodes.length; j++) {
                if (andNodes[j].checkChildrenSame(variables[i])) {
                    rules.push("AND Form: Idempotent Law")
                }
            }
            for (let j = 0; j < orNodes.length; j++) {
                if (orNodes[j].checkChildrenSame(variables[i])) {
                    rules.push("OR Form: Idempotent Law")
                }
            }
        }
        return rules
    }

    /**
     * Used for AND, OR Absorption law
     * Uses form Absorption
     * @param {list} andNodes all AND Nodes found within the Tree
     * @param {list} orNodes all OR Nodes found within the tree
     */
    AbsorptionLaw(andNodes, orNodes) {
        let rules = []
        rules.push.apply(rules, this.formAbsorption(andNodes, "+", "AND"))
        rules.push.apply(rules, this.formAbsorption(orNodes, "•", "OR"))
        return rules
    }

    /**
     * Fprm Absorption retrieves relevent values to put into base Absorption
     * @param {list} nodes nodes relevent to type of law operator 
     * @param {string} secondNode other operator node involved in law 
     * @param {string} form name of the type of form being applied
     */
    formAbsorption(nodes, secondNode, form) {
        let variables = ["A", "B", "C", "1", "0"]
        let rules = []
        for (let i = 0; i < nodes.length; i++) {
            let first = nodes[i].children[0]
            let second = nodes[i].children[1]
            if (first.data === secondNode && variables.includes(second.data)) {
                rules.push.apply(rules, this.baseAbsorption(first, second, form))
            } else if (second.data === secondNode && variables.includes(first.data)) {
                rules.push.apply(rules,this.baseAbsorption(second, first, form))
            }
        }
        return rules
    }

    /**
     * Handles the base of the Absorption law
     * @param {Node} operatorNode operator relevent to the form 
     * @param {Node} secondNode the other node relevent to the law
     * @param {string} form name of the type of form being applied
     */
    baseAbsorption(operatorNode, secondNode, form) {
        let rules = []
        let variables = ["A", "B", "C", "1", "0"]
        if (operatorNode.checkChildrenLeaves()) {
            if (operatorNode.leftValue(secondNode.data) && !operatorNode.rightValue(secondNode.data) && variables.includes(operatorNode.children[1].data)) {
                rules.push(form + " Form: Absorption Law")
            } else if (operatorNode.rightValue(secondNode.data) && !operatorNode.leftValue(secondNode.data) && variables.includes(operatorNode.children[0].data)) {
                rules.push(form + " Form: Absorption Law")
            }
            //Handles the negation in the OR Form
        } else if(operatorNode.absorptionNotAndLeaves()) {
            if (operatorNode.checkLeftNotNode() && !operatorNode.rightValue(secondNode.data) && variables.includes(operatorNode.children[1].data)) {
                let notNode = operatorNode.children[0]
                if(notNode.leftValue(secondNode.data)) {
                    rules.push(form + " Form: Absorption Law")
                }
            } else if (operatorNode.checkRightNotNode() && !operatorNode.leftValue(secondNode.data) && variables.includes(operatorNode.children[0].data)) {
                let notNode = operatorNode.children[1]
                if(notNode.leftValue(secondNode.data)) {
                    rules.push(form + " Form: Absorption Law")
                }
            }
        }
        return rules
    }

    deMorgan(notNodes) {
        let rules = []
        for (let i = 0; i < notNodes.length; i++) {
            if (notNodes[i].leftValue("•")) {
                rules.push("AND Form: De Morgan's Law")
            }
            if (notNodes[i].leftValue("+")) {
                rules.push("OR Form: De Morgan's Law")
            }
        }
        return rules
    }

    /**
     * Used for Double Complement Law
     * If negation has a negation as a child, law found
     * @param {list} notNodes all the NOT Nodes within the Tree
     */
    doubleComplementLaw(notNodes) {
        let rules = []
        for (let i = 0; i < notNodes.length; i++) {
            if (notNodes[i].leftValue('`')) {
                rules.push("Remove: Double Complement Law")
            }
        }
        let root = this.tree.getRoot()
        if(!(root.getData() === '`' && root.leftValue('`'))) {
            rules.push("Add: Double Complement Law")
        }
        return rules
    }

    /**
     * Finds if there is a negation on a zero or on a one
     * @param {list} notNodes all the NOT Nodes within the Tree
     */
    notOneZero(notNodes) {
        let rules = []
        for (let i = 0; i < notNodes.length; i++) {
            if (notNodes[i].children[0].data === "1") {
                rules.push("Negate One")
            } else if (notNodes[i].children[0].data === "0") {
                rules.push("Negate Zero")
            }
        }
        return rules
    }

    /**
     * Evaluate puts the rules together checking for everyone of them
     */
    evaluate() {
        let rules = []
        let andNodes = this.tree.findNodesOfType(["•"])
        let orNodes = this.tree.findNodesOfType(["+"])
        let notNodes = this.tree.findNodesOfType(["`"])
        rules.push.apply(rules, this.commutativeLaw(andNodes, orNodes))
        rules.push.apply(rules, this.associateLaw(andNodes, orNodes))
        rules.push.apply(rules, this.distributiveLaw(andNodes, orNodes))
        rules.push.apply(rules, this.identityLaw())
        rules.push.apply(rules, this.ZeroAndOneLaw())
        rules.push.apply(rules, this.InverseLaw(andNodes, orNodes))
        rules.push.apply(rules, this.IdempotentLaw(andNodes, orNodes))
        rules.push.apply(rules, this.AbsorptionLaw(andNodes, orNodes))
        rules.push.apply(rules, this.doubleComplementLaw(notNodes))
        rules.push.apply(rules, this.notOneZero(notNodes))
        rules.push.apply(rules, this.deMorgan(notNodes))
        if (rules.length === 0) {
            this.title = "No laws can be performed"
        }
        let set = new Set(rules);
        rules = [...set];
        this.rules = rules
        return rules
    }

}

export default FindRulesTree;