import React, { Component } from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import '../../assets/css/BoxItem.css';

/**
 * Component BoxItem is what appears in a box after an item has been dragged into it, editable means can be dragged
 * 
 * @example
 * return (
 *  <BoxItem editable={true} key={item.uid} uid={item.uid} kill={this.kill} index={index} swap={this.swap} colour={item.colour}>
 *    {item.label}
 * </BoxItem>
 * )
 */
export default class BoxItem extends Component {

  /**
   * Handles when the item (BoxItem) is being dropped into the box
   */
  handleDrop = (e) => {
    if(this.props.editable) {
      e.stopPropagation();
      this.props.swap(e.dragData.index, this.props.index, e.dragData);
      e.containerElem.style.visibility = "hidden";
    }
  };

  /**
   * Kills the current box item if it is editable
   */
  deleteMe = () => {
    if(this.props.editable) {
      this.props.kill(this.props.uid);
    }
  };

  /**
   * Renders Item within Box, DragDropContainer used so you can drag them, DropTarget so can rearrange them while in the box
   */
  render() {
    return (
      <div className="box_item_component" style={{ display: 'inline-block' }}>

        <DragDropContainer targetKey="boxItem"
          dragData={{ label: this.props.children, index: this.props.index, colour: this.props.colour }}
          onDrop={this.deleteMe} disappearDraggedElement={true} dragHandleClassName="grabber"> 

          <DropTarget onHit={this.handleDrop} targetKey="boxItem">
            {this.props.editable &&
            <div className="grabber">
              <div className="outer" style={{ textAlign: "center" }}>
                <div className="item" style={{ backgroundColor: this.props.colour, textAlign: "center" }}>
                  <span>{this.props.children}</span>
                </div>
              </div>
            </div>
            }
            
            {!this.props.editable && 
            <div className="outer" style={{ textAlign: "center" }}>
              <div className="item" style={{ backgroundColor: this.props.colour, textAlign: "center" }}>
                <span>{this.props.children}</span>
              </div>
            </div>
            }
          </DropTarget>

        </DragDropContainer>

      </div>
    );
  }
}