import React, { Component } from 'react';
import { Table } from 'reactstrap';

/**
 * Component that displays a table include the Boolean identities rules.
 * 
 * @example
 * return (
 *  <RulesTable/>
 * )
 */
class RulesTable extends Component {
    /**
     * Renders Rules Table
     */
    render() {
        return (
            <div className="RulesTable">
                <br/>
                <Table style={{background: "white"}} striped size="sm" responsive="sm" >
                    <thead>
                        <tr>
                            <th scope="row">#</th>
                            <th>AND Form</th>
                            <th>OR Form</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Commutative Law</th>
                            <td>A•B=B•A</td>
                            <td>A+B=B+A</td>
                        </tr>
                        <tr>
                            <th scope="row">Associate Law</th>
                            <td>(A•B)•C=A•(B•C)</td>
                            <td>(A+B)+C=A+(B+C)</td>
                        </tr>
                        <tr>
                            <th scope="row">Distributive Law</th>
                            <td>(A•B)+C=(A+C)•(B+C)</td>
                            <td>(A+B)•C=(A•C)+(B•C)</td>
                        </tr>
                        <tr>
                            <th scope="row">Identity Law</th>
                            <td>A•1=A</td>
                            <td>A+0=A</td>
                        </tr>
                        <tr>
                            <th scope="row">Zero and One Law</th>
                            <td>A•0=0</td>
                            <td>A+1=1</td>
                        </tr>
                        <tr>
                            <th scope="row">Inverse Law</th>
                            <td>A•A`=0</td>
                            <td>A+A`=1</td>
                        </tr>
                        <tr>
                            <th scope="row">Idempotent Law</th>
                            <td>A•A=A</td>
                            <td>A+A=A</td>
                        </tr>
                        <tr>
                            <th scope="row">Absorption Law</th>
                            <td>A•(A+B)=A</td>
                            <td>A+(A•B)=A<br />A+(A`•B)=A+B</td>
                        </tr>
                        <tr>
                            <th scope="row">Double Complement Law</th>
                            <td>(A`)`=A</td>
                            <td> </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}
export default RulesTable;