import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import first from '../../../assets/images/beach.jpg'
import second from '../../../assets/images/mobile.png'
import third from '../../../assets/images/tea.jpg'
import fourth from '../../../assets/images/buttonOff.png'
import fifth from '../../../assets/images/buttonOn.png'

/**
 * Component handling the Operators Carousel.
 * 
 * @example
 * return (
 *  <Operators ref="operators" activeIndex={this.state.activeIndex} onChangeIndex={this.onChangeIndex.bind(this)} />
 * )
 */
class Operators extends Component {
    /**
     * Constructor for Operators Carousel
     * @param {number} props.activeIndex Index for which slide is currently displayed in Carousel
     * @param {Function} props.onChangeIndex Updates the active index
     */
    constructor(props) {
        super(props);
        this.state = { 
            activeIndex: 0, 
            cotnrols: false,
            items: [    
            {
                src: first,
                altText: 'Slide 1',
                caption: ''
            },
            {
                src: second,
                altText: 'Slide 2',
                caption: ''
            },
            {
                src: third,
                altText: 'Slide 3',
                caption: ''
            },
            {
                src: fourth,
                altText: 'Slide 3',
                caption: ''
            }] };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.onChangeIndex = this.onChangeIndex.bind(this);
        this.changeImage = this.changeImage.bind(this);
    }

    /**
     * Handles when component updates
     * @param {*} prevProps previous properties of the component
     */
    componentDidUpdate(prevProps) {
        if(this.props.activeIndex !== prevProps.activeIndex) {
            this.setState({activeIndex : this.props.activeIndex})
            if(this.props.activeIndex === 3) {
                this.setState({controls: true})
            }
        }
    }

    /**
     * when index inside Component is changed change parent Component props
     * @param {number} newIndex new index to change image to
     */
    onChangeIndex(newIndex) {
        this.props.onChangeIndex(newIndex);
        if(newIndex === 3) {
            this.setState({controls: true})
        }
    }


    /**
     * Sets animation to true
     */
    onExiting() {
        this.animating = true;
    }

    /**
     * Sets animation to false
     */
    onExited() {
        this.animating = false;
    }

    /**
     * Go to the next image/index
     */
    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
        this.onChangeIndex(nextIndex)
    }

    /**
     * Go to the previous image/index
     */
    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
        this.onChangeIndex(nextIndex)
    }

    /**
     * Change index of image to new one passed in
     * @param {number} newIndex 
     */
    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
        this.onChangeIndex(newIndex)
    }

    /**
     * Switches the last image to a different one
     */
    changeImage = () => {
        if(this.state.items[3].src === fourth) {
            let items = this.state.items
            items[3].src = fifth
            this.setState({items: items})
        } else {
            let items = this.state.items
            items[3].src = fourth
            this.setState({items: items})
        }
    }

    /**
     * Render the Carousel using the state information
     */
    render() {
        const { activeIndex } = this.state;

        //Done for each item within items, creates slides for Carousel
        var slides = this.state.items.map((item) => {
            return (
                <CarouselItem interval={false} 
                    autoPlay={false}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}>
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption captionText={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <Carousel 
                interval={false} 
                enableTouch={true}
                autoPlay={false}
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                {this.state.controls && <div>
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </div>}
                
            </Carousel>
        );
    }
}


export default Operators;