import React, { Component } from 'react'

/**
 * Component for displaying part 1 in tutorial 3.
 * 
 * @example
 * return (
 *  <ThreePart1/>
 * )
 */
class ThreePart1 extends Component {
    /**
     * Renders part1, desribes basic idea of Boolean Algebra
     */
    render() {
        return (
            <div>
                <h3 style={{textAlign: "center"}}>Boolean Algebra</h3>
                <div className="scenario">
                    <p>When we start to make our equation longer we can look at rules to simplify them, this is called Boolean Algebra.</p>
                    <p>There are different rules we can apply to shorten our equation or to rearrange it to hopefully use another to simplify it.</p>
                    <p>The rules table will be displayed in the next part of the tutorial.</p>
                </div>
            </div>
        )
    }
}

export default ThreePart1;