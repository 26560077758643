import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, CardTitle, CardText, Progress, Alert, Input, Label } from 'reactstrap';

/**
 * Component handling logic and display for the let's learn page.
 * 
 * @example
 * return (
 *  <LearnPage/>
 * )
 */
class LearnPage extends Component {
    /**
     * Constructor for LearnPage
     */
    constructor(props) {
        super(props);
        this.state = {
            cookies: props.cookies,
            tutorial1: false,
            tutorial2: false,
            tutorial3: false,
            tutorial4: false,
            quiz1: false,
            quiz2: false,
            skipCode: false,
            nextText: "Progress 0%: Start With Tutorial 1!",
            currentProgress: "0",
            codeInput: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    
    /**
     * When component is displayed, set progress and state according to what tutorials & quizzes pupil has already done
     */
    componentDidMount() {
        window.scrollTo(0, 0)
        if(this.state.cookies.get('tutorial1')) {
            this.setState({tutorial1: true, currentProgress: "14", nextText: "Progress 14%: Try Quiz 1 Next!"})
        }
        if(this.state.cookies.get('quiz1')) {
            this.setState({quiz1: true, currentProgress: "29", nextText: "Progress 29%: Try Tutorial 2 Next!"})
        }
        if(this.state.cookies.get('tutorial2')) {
            this.setState({tutorial2: true, currentProgress: "43", nextText: "Progress 43%: Try Quiz 2 Next!"})
        }
        if(this.state.cookies.get('quiz2')) {
            this.setState({quiz2: true, currentProgress: "57", nextText: "Progress 57%: Try Tutorial 3 Next!"})
        }
        if(this.state.cookies.get('tutorial3')) {
            this.setState({tutorial3: true, currentProgress: "71", nextText: "Progress 71%: Try Tutorial 4 Next!"})
        }
        if(this.state.cookies.get('tutorial4')) {
            this.setState({tutorial4: true, currentProgress: "86", nextText: "Progress 86%: Try Quiz 3 Next!"})
        }
        if(this.state.cookies.get('quiz3')) {
            this.setState({tutorial4: true, currentProgress: "100", nextText: "Progress 100%: Everything is complete, give the tools on the Tools page a try!"})
        }
        if(this.state.cookies.get('code')) {
            this.setState({skipCode: true, currentProgress: "100", nextText: "Progress 100%: Everything is complete, give the tools on the Tools page a try!"})
        }
    }

    /**
     * If pupil has done previous tutorial/quiz set the card to success (green), if not danger (red)
     * @param {string} index index of the card setting colour too
     */
    setColourCard(index) {
        if(this.state.skipCode) {
            return "success";
        } else {
            switch(index) {
                case "t2":
                    if(this.state.quiz1) {
                        return "success"
                    } 
                    break;
                case "t3":
                    if(this.state.quiz2) {
                        return "success"
                    }
                    break; 
                case "t4":
                    if(this.state.tutorial3) {
                        return "success"
                    }
                    break; 
                case "q1":
                    if(this.state.tutorial1) {
                        return "success"
                    }
                    break; 
                case "q2":
                    if(this.state.tutorial2) {
                        return "success"
                    }
                    break; 
                case "q3":
                    if(this.state.tutorial4) {
                        return "success"
                    }
                    break; 
                default:
                    break;
            }
            return "danger"
        }
    }

    /**
     * Handles when the value within text box changes and sets state
     * @param {string} e data from text box
     */
    handleChange(e) {
        this.setState({codeInput: e.target.value});
    }

    /**
     * Handles when Teacher Code is submitted, if is technofun pass all tutorials & quizzes
     * @param {string} e data from text box
     */
    handleClick(e) {
        if(this.state.codeInput === "technofun") {
            this.state.cookies.set('code','true', {path: '/'})
            this.setState({skipCode: true, currentProgress: "100", nextText: "Progress 100%: Congrats, Everything is Complete!"})
        }
    }

    /**
     * Used when Reset button is pressed, resets all tutorials & quizzes
     */
    resetAll() {
        this.state.cookies.remove('code','true', {path: '/'})
        this.state.cookies.remove('tutorial1','true', {path: '/'})
        this.state.cookies.remove('tutorial2','true', {path: '/'})
        this.state.cookies.remove('tutorial3','true', {path: '/'})
        this.state.cookies.remove('tutorial4','true', {path: '/'})
        this.state.cookies.remove('quiz1','true', {path: '/'})
        this.state.cookies.remove('quiz2','true', {path: '/'})
        this.state.cookies.remove('quiz3','true', {path: '/'})
        this.setState({
            tutorial1: false,
            tutorial2: false,
            tutorial3: false,
            tutorial4: false,
            quiz1: false,
            quiz2: false,
            skipCode: false,
            nextText: "Progress 0%: Start With Tutorial 1!",
            currentProgress: "0"
        })
    }

    /**
     * Renders the display of the let's learn page, shows cards for each tutorial & quiz
     */
    render() {
        return ( 
            <div className="inner-container">
                <div className="content">
                    <Alert color="primary">
                        {this.state.nextText}
                    </Alert>
                    <Progress value={this.state.currentProgress}/>
                    <br/>
                    <Container>
                        <h2>Tutorials</h2>
                        <div className="scenario">
                            <Row>
                                <Col md="6" style={{padding: '10px'}}> 
                                    <Card body inverse color="success">
                                        <CardTitle>Tutorial 1</CardTitle>
                                        <CardText>This tutorial teaches you about the operators within Boolean Logic and applies them in real life scenarios.</CardText>
                                        <Link to='/tutorial/1'><Button color="secondary">Let's go!</Button></Link>
                                    </Card>
                                </Col>
                                <Col md="6" style={{padding: '10px'}}>
                                    <Card body inverse color={this.setColourCard("t2")}>
                                        <CardTitle>Tutorial 2</CardTitle>
                                        <CardText>This tutorial teaches you all about truth tables and how to construct them depending on the equation. </CardText>
                                        { (this.state.skipCode || this.state.quiz1) && <Link to='/tutorial/2'><Button color="secondary">Let's go!</Button></Link>}
                                        { (!this.state.skipCode && !this.state.quiz1) && <CardText>Quiz 1 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" style={{padding: '10px'}}>
                                    <Card body inverse color={this.setColourCard("t3")}>
                                        <CardTitle>Tutorial 3</CardTitle>
                                        <CardText>This tutorial teaches you about Boolean Algebra and introduces laws, looking at how they can be used to simplify an equation.</CardText>
                                        { (this.state.skipCode || this.state.quiz2) && <Link to='/tutorial/3'><Button color="secondary">Let's go!</Button></Link>}
                                        { (!this.state.skipCode && !this.state.quiz2) && <CardText>Quiz 2 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                                <Col md="6" style={{padding: '10px'}}>
                                    <Card body inverse color={this.setColourCard("t4")}>
                                        <CardTitle>Tutorial 4</CardTitle>
                                        <CardText>This tutorial gets you to practice simplifying equations using Boolean identities and rules, make it as short as you can!</CardText>
                                        { (this.state.skipCode || this.state.tutorial3) && <Link to='/tutorial/4'><Button color="secondary">Let's go!</Button></Link>}
                                        { (!this.state.skipCode && !this.state.tutorial3) && <CardText>Tutorial 3 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                        <h2>Quiz time!</h2>
                        <div className="scenario">
                            <Row>
                                <Col>
                                    <Card body inverse color={this.setColourCard("q1")}>
                                        <CardTitle>Quiz 1</CardTitle>
                                        <CardText>This quiz tests if you can build equations given real life scenarios.</CardText>
                                        { (this.state.skipCode || this.state.tutorial1) && <Link to='/quiz/1'><Button color="secondary">Test me</Button></Link>}
                                        { (!this.state.skipCode && !this.state.tutorial1) && <CardText>Tutorial 1 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Card body inverse color={this.setColourCard("q2")}>
                                        <CardTitle>Quiz 2</CardTitle>
                                        <CardText>This quiz tests you to see if you can fill in the truth tables correctly.</CardText>
                                        { (this.state.skipCode || this.state.tutorial2) && <Link to='/quiz/2'><Button color="secondary">Test me</Button></Link>}
                                        { (!this.state.skipCode && !this.state.tutorial2) && <CardText>Tutorial 2 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Card body inverse color={this.setColourCard("q3")}>
                                        <CardTitle>Quiz 3</CardTitle>
                                        <CardText>This quiz tests if you can simplify equations to their simplest form.</CardText>
                                        { (this.state.skipCode || this.state.tutorial4) && <Link to='/quiz/3'><Button color="secondary">Test me</Button></Link>}
                                        { (!this.state.skipCode && !this.state.tutorial4) && <CardText>Tutorial 4 needs to be completed before this.</CardText>}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </Container>
                <br/>
                <Button outline color="danger" onClick={() => this.resetAll()}>Start Over</Button>
                <p><b>Resets all tutorials and quizzes.</b></p>
                <br/>
                <Label for="inputText">Teacher Code:</Label>
                <Input type="text" id="inputText" onChange={this.handleChange}/>
                <Input type="button" value="Submit" onClick={this.handleClick}/>
            </div>
          </div>
        )
    }
}

export default LearnPage;