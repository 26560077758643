import React, { Component } from 'react'
import { Button } from 'reactstrap'
import TeachTable from './TeachTable'
import Generate from '../TreeParser/Generate'
import Tree from '../TreeParser/Tree'

/**
 * Component that displays the Test Truth table tool page, can also generate a random equation.
 * 
 * @example
 * return (
 *  <TablePage/>
 * )
 */
class TablePage extends Component {
    /**
     * Constructor for TablePage
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "",
        }
        this.onChangeEquation = this.onChangeEquation.bind(this)
    }
    
    /**
     * Changes the current equation displayed inside TeachTable component
     * @param {string} newEquation new equation that is set
     */
    onChangeEquation = (newEquation) => {
        this.setState({
            equation: newEquation
        });
    }

    /**
     * Generates a random equation to go into TeachTable component
     * Acivated when Generate button is clicked on
     */
    generateEquation() {
        let operands = ['1','0','A','B','C','(A`)','(B`)','(C`)']
        let operators = ['+','•', '⊕']
        let equation = ""
        let rndBracket = this.randomBetween(0,2)

        if (rndBracket === 0) {
            equation += '('
        }

        for(let i = 1; i<=2; i++) {
            let rnd = this.randomBetween(0,operands.length)
            equation += operands[rnd]
            operands.splice(rnd, 1)

            if (rndBracket === 0 && i === 2) {
                equation += ')'
            }

            rnd = this.randomBetween(0,operators.length)
            equation += operators[rnd]
            operators.splice(rnd, 1)

            if (rndBracket === 1 && i === 1) {
                equation += '('
            }

            if(i === 2) {
                rnd = this.randomBetween(0,operands.length)
                equation += operands[rnd]
            }
        }

        if (rndBracket === 1) {
            equation += ')'
        }

        let tree = new Tree(new Generate(equation, false).evaluate(true))
        equation = tree.printOrder()
        this.setState({equation: equation})
        this.refs.TeachTable.generateEquationCalled(equation)
    }

    /**
     * Generates a random number between the two values
     * @param {number} min minimum the number can be
     * @param {number} max maximum the number can be
     */
    randomBetween(min, max) {
        return parseInt(min + Math.random() * (max - min))
    }

    /**
     * Renders the TeachTable component for the tool
     */
    render() {
        return (
        <div className="inner-container">
            <div className="content">
                <p>Press Generate to make a random expression along with it's truth table:</p>
                <Button onClick={() => this.generateEquation()}>Generate</Button>
                </div>
                <br/>
            <TeachTable ref="TeachTable" equation={this.state.equation} onChangeEquation={this.onChangeEquation}/>
      </div>
    );
  }
}

export default TablePage;

