import React, { Component } from 'react'
import Box from '../DragDrop/Box'
import Components from '../DragDrop/Components'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import FindRules from '../TreeParser/RulesTree/FindRulesTree'
import ApplyRules from '../TreeParser/RulesTree/ApplyRulesTree'
import Generate from '../TreeParser/Generate'
import Tree from '../TreeParser/Tree'
import RulesTable from '../RuleSimplify/RulesTable'

/**
 * Component for simplfiying equations through Boolean identities
 * Get's the pupil to put in the answer instead giving it to them
 */
class TestRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equation: "",
            equationList: [],
            appliedRules: [],
            tree: new Tree(),
            correct: false,
            rulesButton: true,
            doneButton: false,
            rules: [],
            checkEquation: "",
            finalEquation: "",
            incorrect: false,
            title: "",
            reset: false,
            guessCorrect: false,
            width: window.innerWidth,
            popoverOpen: false,
            editable: true
        }
        this.onChangeEquation = this.onChangeEquation.bind(this)
    }
    
    /**
     * Handles setting initial state when component is mounted
     */
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        if(this.props.equation) {
           this.onChangeEquation(this.props.equation, true)
           if(!this.props.editable) {
               this.setState({editable: false})
            }
        }
        if(this.props.equationList && this.props.appliedRules) {
            this.setState({equationList: this.props.equationList, appliedRules: this.props.appliedRules,
            checkEquation: this.props.currentCheckEquation, finalEquation: this.props.finalEquation})
            if(this.props.currentCheckEquation) {
                this.setState({ checkEquation: this.props.currentCheckEquation, doneButton: true, reset: true, editable: true, rules: [], rulesButton: false })
            }
        }
    }

    /**
     * Handles when component updates
     * @param {*} prevProps previous properties of the component
     */
    componentDidUpdate(prevProps) {
        if(this.props.equation !== prevProps.equation && this.props.equation !== prevProps.equationList[0]) {
            this.setState({ equation: this.props.equation, equationList: this.props.equationList, appliedRules: this.props.appliedRules, tree: new Tree(), correct: false, rulesButton: true,
            doneButton: false, rules: [], checkEquation: this.props.currentCheckEquation, incorrect: false, title: "", reset: false, guessCorrect: false, finalEquation: this.props.finalEquation})
            if (this.props.equation) {
                this.onChangeEquation(this.props.equation, true)
            }
            if(this.props.currentCheckEquation) {
                this.setState({ doneButton: true, reset: true, rules: [], rulesButton: false, editable: true })
            } else {
                this.setState({editable: false})
            }
            if(this.props.equationList.length > 0) {
                this.setState({reset: true})
            }
            if(this.props.finalEquation === this.props.equation && !this.state.editable) {
                this.setState({rulesButton: false, guessCorrect: true})
            }
        }
    }


    /**
     * Removes event listener when component is unmounted
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    /**
     * Handles the size of the window changing
     */
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    /**
     * Toggles displays the rules table popover
     */
    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    /**
     * Changes the current equation and binds to Box component, also generates new tree
     * @param {string} newEquation new equation that state will be set to
     * @param {boolean} correct whether the equation is correct or not
     */
    onChangeEquation(newEquation, correct) {
        if (this.props.equationList && this.props.equationList.length !== 0) {
            this.props.lastEquationInputted(newEquation)
        }
        let tree = new Tree(new Generate(newEquation).evaluate(correct))
        if(!this.state.doneButton) {
            this.setState({ equation: newEquation, correct: correct, tree: tree, incorrect: false, guessCorrect: false, rulesButton: true, title: "", rules: [] })
        } else {
            this.setState({ equation: newEquation, correct: correct, tree: tree})
        }
    }

    /**
     * Finds rules that can be applied to the current equation
     */
    findRules() {
        let rules = new FindRules(this.state.tree)
        this.setState({ rules: rules.evaluate(), title: rules.getTitle(), rulesButton: false, incorrect: false, guessCorrect: false })
    }

    /**
     * Applies the law the pupil clicked on to the current equation
     * @param {string} law name of the law that is being applied
     */
    apply(law) {
        let tree = new ApplyRules(law, this.state.tree).evaluate()
        let next = this.state.equationList
        next.push(this.state.equation)

        let applied = this.state.appliedRules
        applied.push(law)

        let newEquation = tree.printOrder()
        
        this.setState({ equation: "", equationList: next, tree: tree, appliedRules: applied, rules: [], 
        doneButton: true, checkEquation: newEquation, reset: true, editable: true })

        if (this.props.equationList) {
            this.props.updateRulesAndEquations(next, applied, newEquation)
            this.props.lastEquationInputted("")
        }
    }

    /**
     * Check if the pupil got the correct answer for the applied rule
     */
    checkCorrect() {
        if (this.state.equation === this.state.checkEquation) {
            this.setState({ incorrect: false, rulesButton: true, doneButton: false, guessCorrect: true, editable: false, checkEquation: "" })
            if(this.state.equation === this.props.finalEquation) {
                this.setState({rulesButton: false})
            }
            if (this.props.correct === false) {
                this.props.onCorrect(this.state.equation)
            }
            if(this.props.currentCheckEquation) {
                this.props.updateRulesAndEquations(this.state.equationList, this.state.appliedRules, "")
                this.props.lastEquationInputted(this.state.equation)
            }
        } else {
            this.setState({ incorrect: true })
        }
    }

    /**
     * Reset the equation, deleting everything
     */
    reset = () => {
        let equation = ""
        if(this.props.equationList && this.props.equationList.length !== 0) {
            equation = this.props.equationList[0]
            this.props.updateRulesAndEquations([], [], "")
            this.props.lastEquationInputted(equation)
        } else if (this.props.equation && this.props.equation !== "") {
            equation = this.props.equation
        }
        this.setState({ equation: equation, equationList: [], appliedRules: [], tree: new Tree(), correct: false, rulesButton: true, 
        doneButton: false, rules: [], checkEquation: "", incorrect: false, title: "", reset: false, guessCorrect: false})
        if(equation !== "") {
            this.onChangeEquation(equation, true)
        }
        if (this.props.editable === false) {
            this.setState({editable: false})
        } else {
            this.setState({editable: true})
        }
    }

    /**
     * Displays the box with the equation, possible rules that can be applied
     * When pupil clicks on rule drag and drop appears for them to put in the answer
     */
    render() {
        const { width } = this.state;
        const isMobile = width <= 480;
        return (
            <div>
                {this.state.appliedRules.map((item, key) => {
                return (
                    <div className="rulesApplied" key={key}>
                        <h2>{this.state.equationList[key]}</h2>
                        <h4>Apply this law:</h4>
                        <h4>{item}</h4>
                    </div>
                    )
                })}
                <div className="drag_things_to_boxes">
                    <div className="things_to_drag">
                        {this.state.editable && <Components/>}
                    </div>
                    <div className="boxes">
                        {this.state.editable && <Box editable={true} targetKey="box" onChangeEquation={this.onChangeEquation} equation={this.state.equation} />}
                        {!this.state.editable && <Box editable={false} targetKey="box" onChangeEquation={this.onChangeEquation} equation={this.state.equation} />}
                    </div>
                </div>
                {this.state.reset && <div><Button style={{ float: 'right'}} outline color="danger" onClick={() => this.reset()}>Reset</Button></div>}
                {!isMobile &&
                    <div style={{ float: "right"}}>
                        <Button id="PopoverLegacy" type="button">All Rules</Button>
                        <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy">
                            <PopoverHeader>Rules</PopoverHeader>
                            <PopoverBody><RulesTable /></PopoverBody>
                        </UncontrolledPopover>
                    </div>}
                    {this.state.rulesButton && this.state.correct && <Button outline color="primary" onClick={() => this.findRules()}>Show Rules</Button>}
                    {this.state.doneButton && this.state.correct && <Button outline color="primary" onClick={() => this.checkCorrect()}>Done</Button>}
                    {this.state.incorrect && <h5>That is not correct, try again!</h5>}
                    {this.state.guessCorrect && <h5>Well done, you're correct!</h5>}
                    {!this.state.rulesButton && !this.state.doneButton && <h5>{this.state.title}</h5>}
                    {this.state.correct && this.state.rules.map((item, key) => {
                    return (
                        <span key={key}><Button outline color="primary" onClick={() => this.apply(item)}>{item}</Button></span>
                        )
                    })}
                {isMobile && <RulesTable />}
            </div>
        );
    }
}

export default TestRules;
