import React, { Component } from 'react'
import RulesHelp from './RulesHelp'

/**
 * Component that displays RulesHelp component within a page, the Rules Simplfier tool displayed 
 * 
 * @example
 * return (
 *  <Rules/>
 * )
 */
class Rules extends Component {
  /**
   * Renders RulesHelp Component
   */
  render() {
    return (
      <div className="inner-container">
        <div style={{ textAlign: "center" }}>
          <h4>Input a valid equation and apply rules you can find!</h4>
        </div>
        <RulesHelp/>
      </div>
    );
  }
}

export default Rules;