import React, { Component } from 'react'
import { DropTarget } from 'react-drag-drop-container'
import BoxItem from './BoxItem'
import trash from '../../assets/images/trash.png'
import { Button } from 'reactstrap'
import Validate from './Validate'
import Generate from '../TreeParser/Generate'
import Tree from '../TreeParser/Tree'
import sizeMe from 'react-sizeme';
var shortid = require('shortid')

/**
 * Component Box handles boolean equation and the drag and drop.
 * 
 * @example 
 * return (
 *  <Box editable={false} targetKey="box" equation={this.state.equation} onChangeEquation={this.onChangeEquation.bind(this)} />
 * )
 */
class Box extends Component {
  /**
   * Constructor for Box
   * @param {string} props.targetKey Set same key for Boxes and Box to drag to eachother
   * @param {boolean} props.editable if set to false items can't be moved
   * @param {string} props.equation Equation visible in Box
   * @param {Function} props.onChangeEquation Function to update/pass equation into parent component
   */
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      equation: "",
      correct: false,
      error: [],
      editable: true
    };
  }


  /**
   * Handles setting initial state when component is mounted
   */
  componentDidMount() {
    this.setState({ equation:  this.props.equation , items: this.applyItems(this.props.equation), error: [] })
    if(this.props.editable === false) {
      this.setState({editable: false})
    }
  }

  /**
   * Used to update component with props
   * @param {*} props properties passed in
   */
  componentWillReceiveProps(props) {
    if(props.equation !== this.state.equation) {
      this.setState({ equation: props.equation,  items: this.applyItems(props.equation), error: [] })
    }
    if(this.props.editable === true) {
      this.setState({editable: true})
    }
  }

  /**
   * Splits up the equation so they are displayed as drag and drop items within box
   * @param {string} equation the equation that will be set inside the box
   */
  applyItems(equation) {
    if (equation !== this.state.equation) {
      let items = []
      for (let i = 0; i < equation.length; i++) {
        let newValue = equation[i]
        if (newValue === "(" || newValue === ")") {
          items.push({ label: newValue, uid: shortid.generate(), colour: 'blue', error: '' });
        } else {
          items.push({ label: newValue, uid: shortid.generate(), colour: 'green', error: '' });
        }
      }
      return items
    }
    return this.state.items
  }

  /**
   * Changes the equation when it is editable
   */
  onChange() {
    if(this.state.editable) {
      this.props.onChangeEquation(this.state.equation, this.state.correct)
    }
  }

  /**
   * Puts all the equation together and validates it
   */
  validate() {
    let equation = ""
    for (let i = 0; i < this.state.items.length; i++) {
      equation += this.state.items[i].label
    }
    if (equation !== "") {
      let newItems = new Validate(this.state.items).evaluate()
      let correct = true;

      //if all items are green then equation is correct
      for (let i = 0; i < newItems.length; i++) {
        let colour = newItems[i].colour;
        if (colour === 'red') {
          correct = false;
        }
      }
      let errorList = [];
      for (let i = 0; i < newItems.length; i++) {
        let error = newItems[i].error;
        let label = newItems[i].label;
        if (error !== "") {
          errorList.push("'" + label + "' : " + error);
        }
      }
      if(correct === true) {
        let tree = new Tree(new Generate(equation).evaluate(correct))
        let newEquation = tree.printOrder()
        if (newEquation !== equation) {
          newItems = this.applyItems(newEquation)
          equation = newEquation
        }
      } 
      this.setState({ equation: equation, items: newItems, correct: correct, error: errorList });
    } else {
      this.setState({ equation: "", items: [], correct: false, error: [] });
    }
    this.onChange();
  }

  /**
   * Handles when an item is dropped inside the box
   */
  handleDrop = (e) => {
    if(this.state.editable) {
      let items = this.state.items.slice();
      items.push({ label: e.dragData.label, uid: shortid.generate(), colour: e.dragData.colour });
      this.setState({ items: items });
      this.validate();
    }
  };

  /**
   * Handles when items are swapped inside the box
   */
  swap = (fromIndex, toIndex, dragData) => {
    if(this.state.editable) {
      let items = this.state.items.slice();
      const item = { label: dragData.label, uid: shortid.generate(), colour: dragData.colour };
      items.splice(toIndex, 0, item);
      this.setState({ items: items });
      this.validate();
    }
  };

  /**
   * Deletes the relevent item
   */
  kill = (uid) => {
    if(this.state.editable) {
      let items = this.state.items.slice();
      const index = items.findIndex((item) => {
        return item.uid === uid
      });
      if (index !== -1) {
        items.splice(index, 1);
      }
      this.setState({ items: items });
      this.validate();
    }
  };

  /**
   * used to clear/delete the equation when reset is clicked
   */
  clearEquation() {
    this.setState({ items: [], equation: "", correct: false, error: [] });
    this.props.onChangeEquation("", false);
  }

  /**
   * Calculates height of box to change size as items are added
   * @param {number} width current width of the box
   */
  calculateHeight(width) {
    let height = 55
    let amount = this.state.items.length
    if (amount === 0) {
      return height.toString() + 'px'
    }
    let max = Math.floor(width / 50)
    if (amount > max) {
      let times = Math.floor(amount / max) + 1
      return (height * times).toString() + 'px'
    }
  }

  /**
   * Renders the display of the box with drag and drop using BoxItems and DropTargets
   */
  render() {
    const { width } = this.props.size
    return (
      <div className="component_box" >
         <DropTarget onHit={this.handleDrop} targetKey={this.props.targetKey} dropData={{ name: this.props.name }}>
          <DropTarget onHit={this.handleDrop} targetKey="boxItem" dropData={{ name: this.props.name }}>
            <div className="box" style={{height: this.calculateHeight(Math.floor(width))}}>
              {this.state.items.map((item, index) => {
                return (
                  <BoxItem editable={this.state.editable} key={item.uid} uid={item.uid} kill={this.kill} index={index} swap={this.swap} colour={item.colour}>
                    {item.label}
                  </BoxItem>
                )
              })}
            </div>
          </DropTarget>
        </DropTarget>
        <div className="equation" >
          { this.state.editable && < DropTarget targetKey="boxItem">
            <img src={trash} alt="small bin icon" width={40} height={40} />
            </DropTarget> }
          { this.state.editable && <Button style={{ float: 'right' }} outline color="danger" onClick={() => this.clearEquation()}>Clear</Button>}
          <h2> {this.state.equation} </h2>
          {this.state.error.map((item, key) => {
            return (
              <span key={key}> {item} <br /> </span>
            )
          })}
          {this.state.editable && <br/>}
        </div>
      </div >
    );
  }
}
export default sizeMe()(Box);