import Node from '../Node'

/**
 * Class that can apply each Boolean identity to a equation Tree.
 */
class ApplyRulesTree {
    /**
     * Constructor for ApplyRulesTree
     * @param {string} rule Rule being applied
     * @param {Tree} tree Tree of current equation
     */
    constructor(rule, tree) {
        this.rule = rule
        this.tree = tree
    }

    /**
     * Used for the AND Commutative law 
     * Switches the children of the first AND Node found
     */
    andCommutative() {
        let andNodes = this.tree.findNodesOfTypeChildren("•")
        andNodes[0].switchChildren()
    }

    /**
     * Used for the AND Commutative law 
     * Switches the children of the first AND Node found
     */
    orCommutative() {
        let orNodes = this.tree.findNodesOfTypeChildren("+")
        orNodes[0].switchChildren()
    }

    /**
     * Used for the AND Associate law
     * @param {list} andNodes all AND Nodes found in Tree 
     */
    andAssociate(andNodes) {
        this.baseAssociate(andNodes, "•")
    }

    /**
     * Used for the OR Associate law
     * @param {list} orNodes all OR Nodes found in Tree 
     */
    orAssociate(orNodes) {
        this.baseAssociate(orNodes, "+")
    }

    /**
     * Used for the AND, OR Associate law
     * Switches values around so the brackets are rearranged
     * @param {list} nodes nodes that are relevent
     * @param {string} value the operator we are looking for
     */
    baseAssociate(nodes, value) {
        let done = false
        for(let i = 0; i < nodes.length; i++) {
            if (!done) {
                if(nodes[i].leftValue(value)) {
                    nodes[i].switchChildren()
                    let firstValue = nodes[i].left()
                    let secondValue = nodes[i].right().left()
                    nodes[i].changeLeftChild(secondValue)
                    nodes[i].right().changeLeftChild(firstValue)
                    nodes[i].right().switchChildren()
                    done = true
                } else if (nodes[i].rightValue(value)) {
                    nodes[i].switchChildren()
                    let firstValue = nodes[i].right()
                    let secondValue = nodes[i].left().right()
                    nodes[i].changeRightChild(secondValue)
                    nodes[i].left().changeRightChild(firstValue)
                    nodes[i].left().switchChildren()
                    done = true
                }
            }
        }
    }

    /**
     * Used for AND Distributive law
     * @param {list} orNodes all OR nodes found in Tree 
     */
    andDistributive(orNodes, andNodes) {
        if (!this.baseDistributive(orNodes, "+", "•")) {
            this.baseDistributiveReverse(andNodes, "•", "+")
        }
    }

    /**
     * Used for OR Distributive law
     * @param {list} andNodes all AND nodes found in Tree
     */
    orDistributive(andNodes, orNodes) {
        if (!this.baseDistributive(andNodes, "•", "+")) {
            this.baseDistributiveReverse(orNodes, "+", "•")
        }
    }

    /**
     * Base Distributive law, used by both AND & OR Distributive law 
     * @param {list} nodes list of relevent nodes to perform law on
     * @param {string} topNode the top level node that is revelent
     * @param {string} lawOperator the lower level node relevent to the law
     */
    baseDistributive(nodes, topNode, lawOperator) {
        let done = false
        for (let i = 0; i < nodes.length; i++) {
            if (!done) {
                if (nodes[i].operatorOneChildDistributive(lawOperator)) {
                    let firstValue = nodes[i].returnChildrenVariable()
                    let index = nodes[i].getOtherIndex(firstValue.data)
                    let secondValue = nodes[i].children[index].children[0]
                    let thirdValue = nodes[i].children[index].children[1]
                    nodes[i].changeDataAndChildren(lawOperator, topNode, topNode)
                    nodes[i].children[0].changeChildren(secondValue, firstValue)
                    nodes[i].children[1].changeChildren(thirdValue, firstValue)
                    done = true
                } 
            }
        }
        return done
    }

    /**
     * Base Distributive law, used by both AND & OR Distributive law 
     * @param {list} nodes list of relevent nodes to perform law on
     * @param {string} topNode the top level node that is revelent
     * @param {string} lawOperator the lower level node relevent to the law
     */
    baseDistributiveReverse(nodes, topNode, lawOperator) {
        let done = false
        for (let i = 0; i < nodes.length; i++) {
            if (!done) {
                if (nodes[i].operatorBothChild(lawOperator)) {
                    var allCounts = [0,0,0]
                    allCounts = this.whichVariables(nodes[i].left().left().returnValueOrNotChildValue(), allCounts[0], allCounts[1], allCounts[2])
                    allCounts = this.whichVariables(nodes[i].left().right().returnValueOrNotChildValue(), allCounts[0], allCounts[1], allCounts[2])
                    allCounts = this.whichVariables(nodes[i].right().left().returnValueOrNotChildValue(), allCounts[0], allCounts[1], allCounts[2])
                    allCounts = this.whichVariables(nodes[i].right().right().returnValueOrNotChildValue(), allCounts[0], allCounts[1], allCounts[2])
                    
                    if(this.changeNodesWithValue(allCounts[0], 'A', nodes[i], lawOperator, topNode)) {
                        done = true
                    } else if(this.changeNodesWithValue(allCounts[1], 'B', nodes[i], lawOperator, topNode)) {
                        done = true
                    } else if(this.changeNodesWithValue(allCounts[2], 'C', nodes[i], lawOperator, topNode)) {
                        done = true
                    }
                }
            }
        }
    }

    changeNodesWithValue(count, value, node, lawOperator, topNode) {
        if(count >= 2) {
            if(node.left().left().returnValueOrNotChildValue() === value) {
                if(node.right().left().returnValueOrNotChildValue() === value) {
                    return this.manipulateNodesDistributive(node.left().right(), node.right().right(), node.right().left(), lawOperator, topNode, node)
                } else if(node.right().right().returnValueOrNotChildValue() === value) {
                    return this.manipulateNodesDistributive(node.left().right(), node.right().left(), node.right().right(), lawOperator, topNode, node)
                }
            } else if(node.left().right().returnValueOrNotChildValue() === value) {
                if(node.right().left().returnValueOrNotChildValue() === value) {
                    return this.manipulateNodesDistributive(node.left().left(), node.right().right(), node.right().left(), lawOperator, topNode, node)
                } else if(node.right().right().returnValueOrNotChildValue() === value) {
                    return this.manipulateNodesDistributive(node.left().left(), node.right().left(), node.right().right(), lawOperator, topNode, node)
                }
            }
        }
        return false
    }

    manipulateNodesDistributive(firstNode, secondNode, thirdNode, lawOperator, topNode, node) {
        node.changeDataAndNodes(lawOperator, new Node(topNode), thirdNode)
        node.left().changeChildren(firstNode,secondNode)
        return true
    }

    whichVariables(value, countA, countB, countC) {
        switch(value) {
            case 'A':
                countA++
                break;
            case 'B':
                countB++
                break;
            case 'C':
                countC++
                break;
            default:
                break;
        }
        return [countA, countB, countC]
    }

    /**
     * Used for AND Absorption law 
     * @param {list} andNodes all AND Nodes found in Tree
     */
    andAbsorption(andNodes) {
        this.formAbsorption(andNodes, "+")
    }

    /**
     * Used for OR Absorption law
     * @param {list} orNodes all OR Nodes found in Tree
     */
    orAbsorption(orNodes) {
        this.formAbsorption(orNodes, "•")
    }

    /**
     * Used by both AND & OR Absorption law
     * Retrieves relevent Nodes to put into base Absorption
     * @param {list} nodes nodes that want to find law within
     * @param {string} secondNode other node relevent in law
     */
    formAbsorption(nodes, secondNode) {
        let done = false 
        let variables = ["A", "B", "C", "1", "0"]
        let rules = []
        for (let i = 0; i < nodes.length; i++) {
            if(!done) {
                let changeNode = nodes[i]
                let first = nodes[i].children[0]
                let second = nodes[i].children[1]
                if (first.data === secondNode && variables.includes(second.data)) {
                    rules.push.apply(rules, this.baseAbsorption(first, second, changeNode))
                    done = true
                } else if (second.data === secondNode && variables.includes(first.data)) {
                    rules.push.apply(rules,this.baseAbsorption(second, first, changeNode))
                    done = true
                }
            }
        }
        return rules
    }

    /**
     * Performs the change for the Absorption law
     * @param {Node} operatorNode relevent child Node to the operator
     * @param {Node} secondNode other child Node
     * @param {Node} change original node that is being changed
     */
    baseAbsorption(operatorNode, secondNode, change) {
        let rules = []
        let variables = ["A", "B", "C", "1", "0"]
        if (operatorNode.checkChildrenLeaves()) {
            if (operatorNode.children[0].data === secondNode.data && operatorNode.children[1].data !== secondNode.data && variables.includes(operatorNode.children[1].data)) {
                change.changeValueNoChildren(secondNode.data)
            } else if (operatorNode.children[1].data === secondNode.data && operatorNode.children[0].data !== secondNode.data && variables.includes(operatorNode.children[0].data)) {
                change.changeValueNoChildren(secondNode.data)
            }
            //Handles the negation part in NOT Absorption
        } else if(operatorNode.absorptionNotAndLeaves()) {
            if (operatorNode.checkLeftNotNode() && !operatorNode.rightValue(secondNode.data) && variables.includes(operatorNode.children[1].data)) {
                let notNode = operatorNode.children[0]
                if(notNode.leftValue(secondNode.data)) {
                    change.changeChildren(secondNode,operatorNode.children[1])
                }
            } else if (operatorNode.checkRightNotNode() && !operatorNode.leftValue(secondNode.data) && variables.includes(operatorNode.children[0].data)) {
                let notNode = operatorNode.children[1]
                if(notNode.leftValue(secondNode.data)) {
                    change.changeChildren(secondNode,operatorNode.children[0])
                }
            }
        }
        return rules
    }

    /**
     * Used for AND Identity law
     * @param {list} andNodes all AND Nodes found within the Tree
     */
    andIdentity(andNodes) {
        this.baseIdentityZero(andNodes, "1", "0", "1")
    }

    /**
     * Used for OR Identity law
     * @param {list} andNodes all OR Nodes found within the Tree
     */
    orIdentity(orNodes) {
        this.baseIdentityZero(orNodes, "0", "0", "1")
    }

    /**
     * Used for the base of Identity and Zero & One Law
     * @param {list} nodes Nodes that are relevent to operator
     * @param {*} value other value looking for revelent to the law
     * @param {*} index1 index of child of a Node
     * @param {*} index2 index of child of a Node
     */
    baseIdentityZero(nodes, value, index1, index2) {
        let done = false
        for (let i = 0; i < nodes.length; i++) {
            if (!done) {
                if(nodes[i].rightValue(value)) {
                    nodes[i].replaceNode(nodes[i].children[index1])
                    done = true
                } else if (nodes[i].leftValue(value)) {
                    nodes[i].replaceNode(nodes[i].children[index2])
                    done = true
                }
            }
        }
    }

    
    /**
     * Used for AND Zero & One law
     * @param {list} andNodes all AND Nodes found within the Tree
     */
    andZeroOne(andNodes) {
        this.baseIdentityZero(andNodes, "0", "1","0")
    }

    /**
     * Used for OR Zero & One law
     * @param {list} andNodes all OR Nodes found within the Tree
     */
    orZeroOne(orNodes) {
        this.baseIdentityZero(orNodes, "1", "1", "0")
    }

    /**
     * Used for AND Inverse law
     * @param {list} andNodes all AND Nodes found within the Tree
     */
    andInverse(andNodes) {
        this.inverseOperator(andNodes, "0")
    }

    /**
     * Used for OR Inverse law
     * @param {list} andNodes all OR Nodes found within the Tree
     */
    orInverse(orNodes) {
        this.inverseOperator(orNodes, "1")
    }

    /**
     * Used for the base of the Inverse law
     * @param {list} nodes all nodes relevent to operator being used on
     * @param {string} result result of the law want the outcome to be
     */
    inverseOperator(nodes, result) {
        let done = false
        let variables = ["A", "B", "C"]
        for (let i = 0; i < nodes.length; i++) {
            for (let j = 0; j < variables.length; j++) {
                if (!done) {
                    done = nodes[i].checkInverse(variables[j])
                    if (done) {
                        nodes[i].changeValueNoChildren(result)
                    }
                }
            }
        }
    }

    /**
     * Used for AND Idempotent law
     */
    andIdempotent() {
        this.baseIdempotent("•")
    }

    /**
     * Used for OR Idempotent law
     */
    orIdempotent() {
        this.baseIdempotent("+")
    }

    /**
     * Used for base of Idempotent law
     * @param {string} type type of law, operator being performed on
     */
    baseIdempotent(type) {
        let nodes = this.tree.NodesTypeOnlyChildren(type)
        let done = false
        let variables = ["A", "B", "C"]
        for (let i = 0; i < nodes.length; i++) {
            for (let j = 0; j < variables.length; j++) {
                if (!done) {
                    done = nodes[i].checkChildrenSame(variables[j])
                    if (done) {
                        nodes[i].changeValueNoChildren(variables[j])
                    }
                }
            }
        }
    }

    andDeMorgans(notNodes) {
        this.deMorgans(notNodes, '•', '+')
    }

    orDeMorgans(notNodes) {
        this.deMorgans(notNodes, '+', '•')
    }

    deMorgans(notNodes, lawValue, replaceValue) {
        let done = false
        for (let i = 0; i < notNodes.length; i++) {
            if (!done) {
                if(notNodes[i].leftValue(lawValue)) {
                    let leftChild = notNodes[i].left().left()
                    let rightChild = notNodes[i].left().right()
                    let node = new Node(replaceValue)
                    let leftNot = new Node('`')
                    let rightNot = new Node('`')
                    leftNot.addChild(leftChild)
                    rightNot.addChild(rightChild)
                    node.addChild(leftNot)
                    node.addChild(rightNot)
                    notNodes[i].replaceNode(node)
                    done = true
                }
            }
        }
    }

    /**
     * Used for Double Complement Law
     * If there is double negation, replace second one with first one
     * @param {list} notNodes list of all the NOT Nodes within the Tree
     */
    removeDouble(notNodes) {
        let done = false
        for (let i = 0; i < notNodes.length; i++) {
            if (!done) {
                done = notNodes[i].checkSpecificChild(0, "`")
                if (done) {
                    notNodes[i].replaceNode(notNodes[i].children[0])
                    notNodes[i].replaceNode(notNodes[i].children[0])
                }
            }
        }
    }

    addDouble(tree) {
        let topNotNode = new Node('`')
        let notNode = new Node('`')
        topNotNode.addChild(notNode)
        let root = tree.getRoot()
        notNode.addChild(root)
        tree.setRoot(topNotNode)
    }

    /**
     * Used if one negation appears
     */
    negateOne() {
        this.negateChild("1", "0")
    }

    /**
     * Used if zero negation appears
     */
    negateZero() {
        this.negateChild("0", "1")
    }

    /**
     * Used for negating zero or one
     * @param {string} value corresponding value to check if being negated 
     * @param {string} result result of the negation
     */
    negateChild(value, result) {
        let notNodes = this.tree.findNodesOfType(["`"])
        let done = false
        for (let i = 0; i < notNodes.length; i++) {
            if (!done) {
                done = notNodes[i].checkSpecificChild(0, value)
                if (done) {
                    notNodes[i].changeDataNegate(result, notNodes[i])
                }
            }
        }
    }

    /**
     * Brings all the rules together
     * Based on the rule that is clicked on, find the relevent rule and apply it
     */
    evaluate() {
        let tree = this.tree
        let andNodes = tree.findNodesOfType(["•"])
        let orNodes = tree.findNodesOfType(["+"])
        let notNodes = tree.findNodesOfType(["`"])
        switch (this.rule) {
            case "AND Form: Commutative Law":
                this.andCommutative()
                break;
            case "OR Form: Commutative Law":
                this.orCommutative()
                break;
            case "AND Form: Associate Law":
                this.andAssociate(andNodes)
                break;
            case "OR Form: Associate Law":
                this.orAssociate(orNodes)
                break;
            case "AND Form: Distributive Law":
                this.andDistributive(orNodes, andNodes)
                break;
            case "OR Form: Distributive Law":
                this.orDistributive(andNodes, orNodes)
                break;
            case "AND Form: Identity Law":
                this.andIdentity(andNodes)
                break;
            case "OR Form: Identity Law":
                this.orIdentity(orNodes)
                break;
            case "AND Form: Zero and One Law":
                this.andZeroOne(andNodes)
                break;
            case "OR Form: Zero and One Law":
                this.orZeroOne(orNodes)
                break;
            case "AND Form: Inverse Law":
                this.andInverse(andNodes)
                break;
            case "OR Form: Inverse Law":
                this.orInverse(orNodes)
                break;
            case "AND Form: Idempotent Law":
                this.andIdempotent()
                break;
            case "OR Form: Idempotent Law":
                this.orIdempotent()
                break;
            case "AND Form: Absorption Law":
                this.andAbsorption(andNodes)
                break;
            case "OR Form: Absorption Law":
                this.orAbsorption(orNodes)
                break;
            case "OR Form: De Morgan's Law":
                this.orDeMorgans(notNodes)
                break;
            case "AND Form: De Morgan's Law":
                this.andDeMorgans(notNodes)
                break;
            case "Remove: Double Complement Law":
                this.removeDouble(notNodes)
                break;
            case "Add: Double Complement Law":
                this.addDouble(tree)
                break;
            case "Negate One":
                this.negateOne()
                break;
            case "Negate Zero":
                this.negateZero()
                break;
            default:
        }
        return tree
    }
}

export default ApplyRulesTree;