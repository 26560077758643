import React, { Component } from 'react';
import EquationTruth from './EquationTruth'

/**
 * Component that displays EquationTruth component, the Expression Builder tool displayed in a page.
 * 
 * @example
 * return (
 *  <Expression/>
 * )
 */
class Expression extends Component {
  /**
   * Displays EquationTruth Component
   */
  render() {
    return (
      <div className="inner-container">
        <div style={{textAlign : "center"}}>
          <h4>Build a valid equation and the truth table will appear below</h4>
        </div>
        <EquationTruth/>
      </div>
    );
  }
}

export default Expression;