import React, { Component } from 'react'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'
import Part1 from './OnePart1'
import Part2 from './OnePart2'
import Part3 from './OnePart3'

/**
 * Component handling logic and display for Tutorial 1.
 * 
 * @example
 * return (
 *  <OneTutorial cookies={this.props.cookies}/>
 * )
 */
class OneTutorial extends Component {
    /**
     * Constructor for Tutorial1
     * @param {Cookies} props.cookies Cookies passed in to set when tutorial complete
     */
    constructor(props) {
        super(props);
        this.state = {
            showPage1: true,
            showPage2: false,
            showPage3: false,
            tutorialComplete: false,
            cookies: props.cookies,
            button1: "warning",
            button2: "secondary",
            button3: "secondary"
        }
        this.onComplete = this.onComplete.bind(this);
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('tutorial1') || this.state.cookies.get('code')) {
            this.setState({tutorialComplete: true})
        }
    }
    
    /**
     * Tutorial is split into three parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, showPage3: false, button1: "warning", button2: "secondary", button3: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, showPage3: false, button1: "secondary", button2: "warning", button3: "secondary"})
                break;
            case 3:
                this.setState({showPage1: false, showPage2: false, showPage3: true, button1: "secondary", button2: "secondary", button3: "warning"})
                break;  
            default:
                break;
        }
    }

    /**
     * Called when tutorial is completed (part 3)
     */
    onComplete() {
        this.setState({tutorialComplete: true})
        this.state.cookies.set('tutorial1','true', {path: '/'})
    }

    /**
     * Renders display of tutorial 1, displays the different parts with navigation at bottom
     */
    render() {
        return (
            <div className="inner-container">
                <Container>
                    <div className="content">
                        <Row>
                            <Col>
                                {this.state.showPage1 && <Part1/>}
                                {this.state.showPage2 && <Part2/>}
                                {this.state.showPage3 && <Part3 onComplete={this.onComplete}/>}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {this.state.tutorialComplete && 
                                <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                    <ButtonGroup>
                                        <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                        <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>
                                        <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>                    
                                    </ButtonGroup>
                                    <Link to='/letslearn'><Button>Menu</Button></Link>
                                </ButtonToolbar>}

                                {!this.state.tutorialComplete && !this.state.showPage1 &&
                                <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                    <ButtonGroup>
                                        <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                        <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                        {this.state.showPage3 && <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>}
                                    </ButtonGroup>
                                    { this.state.showPage2 && <Button onClick={() => this.changePage(3)}>Next</Button>}
                                </ButtonToolbar>}
                                { !this.state.tutorialComplete && this.state.showPage1 && <Button onClick={() => this.changePage(2)}>Next</Button>}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div> 
        );
    }
}

export default OneTutorial;