import React, { Component } from 'react'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import {Redirect, Link} from 'react-router-dom'
import Part1 from './TwoPart1'
import Part2 from './TwoPart2'
import Part3 from './TwoPart3'


/**
 * Component handling logic and display for Tutorial 2.
 * 
 * @example
 * return (
 *  <TwoTutorial cookies={this.props.cookies}/>
 * )
 */
class TwoTutorial extends Component {
    /**
     * Constructor for Tutorial2
     * @param {Cookies} props.cookies Passes in cookies to mark when Tutorial is complete
     */
    constructor(props) {
        super(props);
        this.state = {
            showPage1: true,
            showPage2: false,
            showPage3: false,
            valuesDone: false,
            operatorsDone: false,
            cookies: props.cookies,
            tutorialComplete: false,
            button1: "warning",
            button2: "secondary",
            button3: "secondary"
        }
        this.onComplete1 = this.onComplete1.bind(this);
        this.onComplete2 = this.onComplete2.bind(this);
        this.onComplete3 = this.onComplete3.bind(this);
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('tutorial2') || this.state.cookies.get('code')) {
            this.setState({tutorialComplete: true})
        }
    }

    /**
     * Tutorial is split into three parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, showPage3: false, button1: "warning", button2: "secondary", button3: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, showPage3: false, button1: "secondary", button2: "warning", button3: "secondary"})
                break;
            case 3:
                this.setState({showPage1: false, showPage2: false, showPage3: true, button1: "secondary", button2: "secondary", button3: "warning"})
                break;  
            default:
                break;
        }
    }

    /**
     * Called when first part of tutorial is completed
     */
    onComplete1() {
        this.setState({valuesDone: true})
    }

    /**
     * Called when second part of tutorial is completed
     */
    onComplete2() {
        this.setState({operatorsDone: true})
    }

    /**
     * Called when third part of tutorial is completed
     */
    onComplete3() {
        this.setState({tutorialComplete: true})
        this.state.cookies.set('tutorial2','true', {path: '/'})
    }

    /**
     * Renders display of tutorial 2, displays different parts with navigation at the bottom
     */
    render() {
        if(this.state.cookies.get('quiz1') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <div className="content">
                            <Row>
                                <Col>
                                    { this.state.showPage1 && <Part1 onComplete={this.onComplete1}/>}
                                    { this.state.showPage2 && <Part2 onComplete={this.onComplete2}/>}
                                    { this.state.showPage3 && <Part3 onComplete={this.onComplete3}/>}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.tutorialComplete && 
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>
                                            <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>                    
                                        </ButtonGroup>
                                        <Link to='/letslearn'><Button>Menu</Button></Link>
                                    </ButtonToolbar>}

                                    {!this.state.tutorialComplete && !this.state.showPage1 &&
                                        <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                            <ButtonGroup>
                                                <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                                <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                                {this.state.showPage3 && <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>}
                                            </ButtonGroup>
                                        { this.state.showPage2 && this.state.operatorsDone && <Button onClick={() => this.changePage(3)}>Next</Button>}
                                        </ButtonToolbar>}
                                    {!this.state.tutorialComplete && this.state.showPage1 && this.state.valuesDone && <Button onClick={() => this.changePage(2)}>Next</Button>}
                            </Col>
                        </Row>
                        </div>
                    </Container>
                </div>
            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default TwoTutorial;