import React, { Component } from 'react'
import TeachTable from '../../Tools/TestTruthTable/TeachTable'
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import {Redirect, Link} from 'react-router-dom'

/**
 * Component handling logic and display for quiz 2.
 * 
 * @example
 * return (
 *  <Quiz2 cookies={this.props.cookies}/>
 * )
 */
class Quiz2 extends Component {
    /**
     * Constructor for Quiz2
     * @param {Cookies} props.cookies Cookies to set when quiz is done
     */
    constructor(props) {
        super(props);
        this.state = {
            equation: "A+1",
            showPage1: true,
            showPage2: false,
            showPage3: false,
            button1: "warning",
            button2: "secondary",
            button3: "secondary",
            firstEquation: "B`",
            secondEquation: "(A+B)•0",
            thirdEquation: "(A•B)⊕C",
            firstEquationGuessed: [[],[],[]],
            secondEquationGuessed: [[],[],[]],
            thirdEquationGuessed: [[],[],[]],
            firstButtons: ["primary","primary","primary"],
            secondButtons: ["primary","primary","primary"],
            thirdButtons: ["primary","primary","primary"],
            currentGuessed: [],
            firstDone: false,
            secondDone: false,
            cookies: props.cookies,
            quizComplete: false
        }
        this.teachTableUpdated = this.teachTableUpdated.bind(this)
    }

    /**
     * Sets initial state when component mounts
     */
    componentDidMount() {
        if(this.state.cookies.get('quiz2') || this.state.cookies.get('code')) {
            this.setState({quizComplete: true})
        }
    }

    /**
     * Changes state equation binding to TeachTable component
     * @param {string} newEquation the new equation state will be set to
     */
    onChangeEquation(newEquation) {
        this.setState({
            equation: newEquation
        });
    }

    /**
     * Used to exchange information to TeachTable and keep previous state
     */
    teachTableUpdated = (guessed, equation, difficulty, easy, medium, hard) => {
        let list = []
        let buttonList = []
        buttonList.push(easy)
        buttonList.push(medium)
        buttonList.push(hard)
        switch(equation) {
            case "B`":
                list = this.state.firstEquationGuessed
                list[difficulty-1] = guessed
                this.setState({firstEquationGuessed: list, firstButtons: buttonList})
                break;
            case "(A+B)•0":
                list = this.state.secondEquationGuessed
                list[difficulty-1] = guessed
                this.setState({secondEquationGuessed: list, secondButtons: buttonList})
                break;
            case "(A•B)⊕C":
                list = this.state.thirdEquationGuessed
                list[difficulty-1] = guessed
                this.setState({thirdEquationGuessed: list, thirdButtons: buttonList})
                break;
            default:
                break;
        }
    }

    /**
     * OnCorrect binds to TeachTable, handles all difficulties are complete for an equation
     * @param {string} equation the current equation the pupil is working on for the truth table
     * @param {boolean} allDone whether all the difficulties have been done or not
     */
    onCorrect(equation, allDone) {
        if(allDone) {
            switch(equation) {
                case this.state.firstEquation:
                    this.setState({firstDone: true})
                    break;
                case this.state.secondEquation:
                    this.setState({secondDone: true})
                    break;
                case this.state.thirdEquation:
                    this.setState({quizComplete: true})
                    this.state.cookies.set('quiz2','true', {path: '/'})
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Quiz is split into three parts, this handles when user changes parts with navigation
     * @param {number} index changes to the page/part index number
     */
    changePage(index) {
        switch(index) {
            case 1: 
                this.setState({showPage1: true, showPage2: false, showPage3: false, button1: "warning", button2: "secondary", button3: "secondary"})
                break;
            case 2:
                this.setState({showPage1: false, showPage2: true, showPage3: false, button1: "secondary", button2: "warning", button3: "secondary"})
                break;
            case 3:
                this.setState({showPage1: false, showPage2: false, showPage3: true, button1: "secondary", button2: "secondary", button3: "warning"})
                break;  
            default:
                break;
        }
    }

    /**
     * Renders display of quiz 2
     * Checks if user has done tutorial 2, if not is redirected to let's learn
     * Displays three truth tables for pupils to complete for the quiz
     */
    render() {
        if(this.state.cookies.get('tutorial2') || this.state.cookies.get('code')) {
            return (
                <div className="inner-container">
                    <Container>
                        <div className="content">
                            <Row>
                                <Col>
                                    { this.state.showPage1 && <div>
                                        <h3 style={{textAlign: "center"}}>Quiz 2: Truth Tables</h3>
                                            <div className="scenario">
                                                <p>Here you will be given equations along with their truth table, you are expected to fill in the missing values.</p>
                                                <p>You can move onto the next when every difficulty is done.</p>
                                                <p>Easy means the result is left blank, medium is more columns, and hard is everything.</p>
                                            </div>
                                            <div className="scenario">
                                                <TeachTable guessedList={this.state.firstEquationGuessed} equation={this.state.firstEquation} 
                                                editable={false} correct={false} teachTableUpdated={this.teachTableUpdated} onChangeEquation={this.onChangeEquation.bind(this)} 
                                                onCorrect={this.onCorrect.bind(this)} buttonColours={this.state.firstButtons}/>
                                            </div>
                                    </div> }

                                    {this.state.showPage2 && <div className="scenario">
                                            <TeachTable guessedList={this.state.secondEquationGuessed} equation={this.state.secondEquation} 
                                            editable={false} teachTableUpdated={this.teachTableUpdated} correct={false} onChangeEquation={this.onChangeEquation.bind(this)} 
                                            onCorrect={this.onCorrect.bind(this)} buttonColours={this.state.secondButtons}/>
                                    </div> }

                                    {this.state.showPage3 && <div className="scenario">
                                            <TeachTable guessedList={this.state.thirdEquationGuessed} equation={this.state.thirdEquation} 
                                            editable={false} correct={false} teachTableUpdated={this.teachTableUpdated} onChangeEquation={this.onChangeEquation.bind(this)}
                                            onCorrect={this.onCorrect.bind(this)} buttonColours={this.state.thirdButtons}/>
                                    </div> }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.quizComplete && 
                                        <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                            <ButtonGroup>
                                                <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                                <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button>
                                                <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>                    
                                            </ButtonGroup>
                                            <Link to='/letslearn'><Button>Menu</Button></Link>
                                        </ButtonToolbar>}

                                    { !this.state.quizComplete && !this.state.showPage1 &&
                                    <ButtonToolbar style={{display: "flex", justifyContent: "center"}}>
                                        <ButtonGroup>
                                            <Button color={this.state.button1} onClick={() => this.changePage(1)}>1</Button>
                                            <Button color={this.state.button2} onClick={() => this.changePage(2)}>2</Button> 
                                            {this.state.showPage3 && <Button color={this.state.button3} onClick={() => this.changePage(3)}>3</Button>}
                                        </ButtonGroup>
                                        { this.state.showPage2 && this.state.secondDone && <Button onClick={() => this.changePage(3)}>Next</Button>}
                                    </ButtonToolbar>}
                                    { !this.state.quizComplete && this.state.showPage1 && this.state.firstDone && <Button onClick={() => this.changePage(2)}>Next</Button>}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>  
            ); } else {
                return <Redirect to={{pathname: '/letslearn'}}/>
            }
    }
}

export default Quiz2;