import React, { Component } from 'react';
import Box from '../DragDrop/Box'
import { Table } from 'reactstrap';
import { Button } from 'reactstrap';
import ComponentsXOR from '../DragDrop/ComponentsXOR';
import TruthTable from '../ExpressionBuilder/ComputeTruthTable'

/**
 * Component that displays truth table but blanks out parts for pupil to fill in.
 * use GuessedList if want to keep state of current pupil guess for each difficulty in parent component
 * 
 * @example
 * return (
 *  <TeachTable equation={this.state.equation} onChangeEquation={this.onChangeEquation}/>
 *  <TeachTable guessedList={this.state.firstEquationGuessed} equation={this.state.firstEquation} 
 * )
 */
class TeachTable extends Component {
  /**
   * Constructor for TeachTable
   * @param {string} props.equation Equation displayed within Box
   * @param {Function} props.onChangeEquation Function to update equation between components
   * @param {List} props.guessedList puts in previous state of what pupil guessed in difficulties (if had prev state)
   * @param {List} props.buttonColours List of the colours of the button difficulties (if had prev state)
   */
  constructor(props) {
    super(props);
    this.state = {
      equation: "",
      correct: false,
      headers: [],
      content: [],
      result: 0,
      offset: 0,
      answer: [],
      guessed: [],
      gussedIndex: [],
      colourIndex: [],
      allPreviousGuessed: [[],[],[]],
      viewDone: false,
      guessedText: "",
      difficulty: 1,
      editable: true,
      easyButton: "primary",
      mediumButton: "primary",
      hardButton: "primary",
      difficultyDone: false,
    }
    this.clickedTruthTable = this.clickedTruthTable.bind(this);
  }

  /**
   * Handles the initial state when component is mounted
   */
  componentDidMount() {
    if(this.props.equation) {
      this.onChangeEquation(this.props.equation, true)
    }
    if(this.props.editable === false) {
      this.setState({editable: false})
    }
    if (this.props.buttonColours) {
      this.setState({easyButton: this.props.buttonColours[0], 
      mediumButton: this.props.buttonColours[1], hardButton: this.props.buttonColours[2]})
    }
  }

  /**
   * Updates the component
   * @param {*} prevProps previous properties of component
   */
  componentDidUpdate(prevProps) { 
    if (this.props.equation && this.props.equation !== prevProps.equation && this.props.equation !== "") {
      this.setState({equation: this.props.equation})
    }
  }

  generateEquationCalled(newEquation) {
    this.onChangeEquation(newEquation, true)
  }

  /**
   * Changes the current equation and binds to box
   * @param {string} newEquation value of the new equation to set to
   * @param {boolean} correct whether equation is correct or not
   */
  onChangeEquation(newEquation, correct) {
    if(this.props.equation) {
      this.props.onChangeEquation(newEquation)
    }
    this.setState({
      equation: newEquation,
      correct: correct,
      headers: [],
      content: [],
      viewDone: false,
      guessedText: "",
      easyButton: "primary",
      mediumButton: "primary",
      hardButton: "primary",
      difficultyDone: false
    });
    if (correct === true && newEquation !=="") {
      let truth = new TruthTable(newEquation)
      this.setState({ equation: newEquation, guessedText: "Fill out the table", content: truth.getContent(), headers: truth.getHeaders(), result: truth.getResult(), offset: truth.getOffset(), viewDone: true })
      this.removeTableDifficulty(truth.getContent(), this.state.difficulty, truth.getResult())
    }
  }

  /**
   * Removes parts of the truth table by setting them to question mark, amount removed depends on the difficulty
   * @param {list} content all the content values
   * @param {number} difficulty difficulty depends on how much of truth table to remove
   * @param {number} result index of the result of the equation
   */
  removeTableDifficulty(content, difficulty, result) {
    let offset = this.state.offset
    let answer = this.duplicateArray(content)
    let colour = this.duplicateArray(content)

    switch(difficulty) {
      case 1:
        for (let i = 0; i < content.length; i++) {
          let row = content[i]
          row[result] = "?"
          let colourRow = colour[i]
          colourRow[result] = "secondary"
        }
        break;
      case 2:
        for (let i = 0; i < content.length; i++) {
          let row = content[i]
          let colourRow = colour[i]
          for (let j = offset; j < row.length; j=j+2) {
            if(row[j] !== undefined) {
              row[j] = "?"
              colourRow[j] = "secondary"
            }
          }
        }
        break;
      case 3:
        for (let i = 0; i < content.length; i++) {
          let row = content[i]
          let colourRow = colour[i]
          for (let j = offset; j < row.length; j++) {
            if(row[j] !== undefined) {
              row[j] = "?"
              colourRow[j] = "secondary"
            }
          }
        }
        break;
      default:
        break;
    }
    let guessedIndex = this.onlyKeepQuestionMarks(content)
    if(this.props.guessedList && this.props.guessedList[difficulty - 1].length !== 0) {
      this.setState({ content: content, answer: answer, gussedIndex: this.props.guessedList[difficulty - 1], colourIndex: colour, guessedText: "Fill out the table" })
    } else if(this.state.allPreviousGuessed[difficulty - 1].length !== 0) {
      this.setState({ content: content, answer: answer, gussedIndex: this.state.allPreviousGuessed[difficulty - 1], colourIndex: colour, guessedText: "Fill out the table" })
    } else {
      this.setState({ content: content, answer: answer, gussedIndex: guessedIndex, colourIndex: colour, guessedText: "Fill out the table" })
    }
  }

  /**
   * only keep the question marks within array, rest set to explanation marks, explanation marks used to compare to correct answer later on
   * @param {list} array array to be changed
   */
  onlyKeepQuestionMarks(array) {
    let duplicate = []
    for (let i = 0; i < array.length; i++) {
      let row = array[i]
      let line = []
      for (let j = 0; j < row.length; j++) {
        if (row[j] === "?") {
          line.push("?")
        } else {
          line.push("!")
        }
      }
      duplicate.push(line)
    }
    return duplicate
  }

  /**
   * Duplicates the array so they do not affect each other
   * @param {list} array the array that is being copied
   */
  duplicateArray(array) {
    let duplicate = []
    for (let i = 0; i < array.length; i++) {
      let row = array[i]
      let line = []
      for (let j = 0; j < row.length; j++) {
        line.push(row[j])
      }
      duplicate.push(line)
    }
    return duplicate
  }

  /**
   * if key is the index of the result of the equation set to highlighted
   * if key is offset - 1 line is added for seperation of left hand side
   * @param {number} key index of value within headers
   */
  setStyle(key) {
    if (key === this.state.result) {
      return { backgroundColor: "#ffa" }
    } else if (key === this.state.offset - 1) {
      return { borderRight: "1px solid" }
    }
  }
  
  /**
   * For the value clicked set to 1 or 0 depending on current value
   * @param {number} key index of the column of current value
   * @param {number} index index of the row of current value
   */
  clickedTruthTable(key, index) {
    let guessedIndex = this.state.gussedIndex
    let row = guessedIndex[key]
    let value = row[index]
    if (value === "?") {
      row[index] = "1"
    } else if (value === "1") {
      row[index] = "0"
    } else if (value === "0") {
      row[index] = "1"
    }
    if(this.props.guessedList) {
      this.props.teachTableUpdated(guessedIndex, this.state.equation, this.state.difficulty,
      this.state.easyButton, this.state.mediumButton, this.state.hardButton)
    }
    let list = this.state.allPreviousGuessed
    list[this.state.difficulty-1] = guessedIndex
    this.setState({ allPreviousGuessed: list, guessedIndex: guessedIndex })
  }

  /**
   * If current answer is correct then set corresponding difficulty to true
   */
  checkCorrect() {
    let answer = this.state.answer
    let guessedIndex = this.state.gussedIndex
    let colour = this.state.colourIndex
    let correct = true
    let wrongIndex = []
    for (let i = 0; i < answer.length; i++) {
      let answerRow = answer[i]
      let guessRow = guessedIndex[i]
      for (let j = 0; j < answerRow.length; j++) {
        if (guessRow[j] !== "!" && guessRow[j]!==answerRow[j]) {
          correct = false
          wrongIndex.push(j)
        } 
      }
    }
    for (let c = 0; c < colour.length; c++) {
      let changeRow = colour[c]
      for(let column = 0; column < changeRow.length; column++) {
        if(wrongIndex.includes(column)) {
          changeRow[column] = "danger"
        } else {
          changeRow[column] = "secondary"
        }
      }
    }
    if (correct === true) {
      let easyButton = this.state.easyButton
      let mediumButton = this.state.mediumButton
      let hardButton = this.state.hardButton
      switch(this.state.difficulty) {
        case 1:
          this.setState({easyButton: "success", guessedText: "You're correct, well done!", colourIndex: colour})
          easyButton = "success"
          break;
        case 2:
          this.setState({mediumButton: "success", guessedText: "You're correct, well done!", colourIndex: colour})
          mediumButton = "success"
          break;
        case 3:
          if(this.state.equation.length < 3) {
            this.setState({mediumButton: "success", guessedText: "You're correct, well done!", colourIndex: colour})
            mediumButton = "success"
          } else {
            this.setState({hardButton: "success", guessedText: "You're correct, well done!", colourIndex: colour})
            hardButton = "success"
          }
          break;
        default:
          break;
      }
      if(this.props.buttonColours) {
        this.props.teachTableUpdated(guessedIndex, this.state.equation, this.state.difficulty, 
        easyButton, mediumButton, hardButton)
      }
      if ((this.state.equation.length < 3 && easyButton === "success" && mediumButton === "success") || 
      (easyButton === "success" && mediumButton === "success" && hardButton === "success")) {
        this.setState({difficultyDone: true, guessedText: "Congrats, you've done all the difficulties!"})
        if(this.props.correct === false) {
            this.props.onCorrect(this.state.equation, true)
        }
      }
    } else {
      this.setState({ guessedText: "That is not correct, try again", colourIndex: colour })
    }
  }

  /**
   * Shows the value within the index provided
   * @param {number} key the index of the column
   * @param {number} index the index of the row
   */
  showIndex(key, index) {
    let guessedIndex = this.state.gussedIndex
    let row = guessedIndex[key]
    return row[index]
  }

  /**
   * Changes the difficulty for how much of the truth table is blanked out
   * @param {number} difficulty index of the difficulty to be set to
   */
  changeDifficulty(difficulty) {
    switch(difficulty) {
      case 1:
        this.setState({difficulty: 1})
        break;
      case 2:
        this.setState({difficulty: 2})
        break;
      case 3:
        this.setState({difficulty: 3})
        break;
      default:
        break;
    }
    if(this.state.equation !== "") {
      let truth = new TruthTable(this.state.equation)
      this.setState({ content: truth.getContent(), headers: truth.getHeaders(), result: truth.getResult(), offset: truth.getOffset(), viewDone: true })
      this.removeTableDifficulty(truth.getContent(), difficulty, truth.getResult())
    }
  }

  /**
   * Uses indexes to find colour of the value
   * @param {number} key index of the row
   * @param {number} index index of the column
   */
  findColour(key, index) {
    let colour = this.state.colourIndex
    let row = colour[key]
    if(row[index] !== "danger") {
      return "secondary"
    }
    return row[index]
  }

  /**
   * Displays Box with truth table with parts missing depending on difficulty
   */
  render() {
    return (
      <div className="inner-container">
        <div style={{ textAlign: "center" }}>
          <p>Choose difficulty, this changes how much of the truth table you need to do youself.</p>
          

          {this.state.equation.length < 3 && 
          <div>
            <Button outline color={this.state.easyButton} onClick={() => this.changeDifficulty(1)}>Easy</Button>
            <Button outline color={this.state.mediumButton} onClick={() => this.changeDifficulty(3)}>Medium</Button>
          </div>}

          {this.state.equation.length > 2 &&
          <div>
            <Button outline color={this.state.easyButton} onClick={() => this.changeDifficulty(1)}>Easy</Button>
            <Button outline color={this.state.mediumButton} onClick={() => this.changeDifficulty(2)}>Medium</Button>
            <Button outline color={this.state.hardButton} onClick={() => this.changeDifficulty(3)}>Hard</Button>
          </div>
          }
          
        </div>
        <div className="drag_things_to_boxes">
          <div className="things_to_drag">
            {this.state.editable && <ComponentsXOR />}
          </div>
          <div className="boxes">
            <Box targetKey="box" editable={this.state.editable} equation={this.state.equation} onChangeEquation={this.onChangeEquation.bind(this)} />
          </div>
          <Table striped size="sm" responsive="sm" style={{ textAlign: "center", maxWidth: "100%" }}>
            <thead>
              <tr>
                {this.state.correct && this.state.headers.map((item, key) => {
                  return (
                    <th style={this.setStyle(key)} key={key}>{item}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.correct && this.state.content.map((element, key) => {
                return (
                  <tr key={key}>
                    {this.state.headers.map((value, index) => {
                      return (
                        <td key={index} style={this.setStyle(index)}>{element[index] === "?" ? <Button outline color={this.findColour(key, index)} onClick={() => this.clickedTruthTable(key, index)}> {this.showIndex(key, index)} </Button> : element[index]}</td>
                      )
                    })}
                  </tr>)
              })}
            </tbody>
          </Table>
          <h4 style={{ textAlign: "center" }}>{this.state.guessedText}</h4>
          {this.state.viewDone && <Button style={{ float: "right" }} outline color="primary" onClick={() => this.checkCorrect()}>Done</Button>}
          <br/>
        </div>
      </div>
    );
  }
}

export default TeachTable