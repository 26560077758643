import React, { Component } from 'react'
import Box from '../../../Tools/DragDrop/Box'
import Operators from './Operators'
import { Button } from 'reactstrap'

/**
 * Component for displaying part 3 in tutorial 1.
 * 
 * @example
 * return (
 *  <OnePart3 onComplete={this.onComplete}/>
 * )
 */
class OnePart3 extends Component {
    /**
     * Constructor for Part3
     * @param {Function} props.onComplete Function called when part 3 is finished
     */
    constructor(props) {
        super(props);
        this.state = {
            equationList: ["C•S","B+C","C⊕T","S`"],
            equation: "C•S",
            activeIndex: 0,
            operator: ["AND (•)","OR (+)","EXCLUSIVE OR (⊕)","NOT ( ` )"],
            explained: ["We can go to the beach in this case if there is no class and it is sunny outside. For the outcome to be true, both of the inputs need to be true.",
            "We can use our phone if it has battery or it is charging. For the outcome to be true both or either of the inputs need to be true. The only case it would be false is if both inputs are false, hence the phone has no battery and is not charging.",
            "Here we can either have coffee or tea, in this case we cannot have both. This means for the output to be true then one of the two inputs needs to be true, not both.",
            "For NOT the output is the opposite to the input, in this case if the switch is on the output/light is off."],
            equationExplained: ["Here is an example equation to represent the AND scenario, C represents there being no class and S represents it being sunny outside.",
            "Here is an example equation to represent the OR scenario, B represents the phone having battery and C represents the phone being on charge.",
            "Here is an example equation to represent the EXCLUSIVE OR scenario, C represents asking for coffee and T represents asking for tea.",
            "Here is an example equation to represent the NOT scenario, S represents the switch and the not negates the output."],
            showScenarioButton: true
        }
    }

    /**
     * Changes the index of the current scenario, changes image and equation
     * @param {number} newIndex new index of operator to display
     */
    onChangeIndex(newIndex) {
        this.setState({
            activeIndex: newIndex,
            equation: this.state.equationList[newIndex]
        });
        if(newIndex === 3) {
            this.setState({showScenarioButton: false})
            this.props.onComplete()
        }
    }

    /**
     * Switches onto the next scenario when next scenario button is pressed
     */
    nextScenario() {
        let newIndex = this.state.activeIndex
        newIndex = newIndex + 1
        if(newIndex < 4) {
            this.onChangeIndex(newIndex)
        }
        if(newIndex === 3) {
            this.setState({showScenarioButton: false})
            this.props.onComplete()
        }
        window.scrollTo(0, 0)
    }

    /**
     * Refers to Operator Carousel to change final image
     */
    scenario4State() {
        this.refs.operators.changeImage()
    }

    /**
     * Renders part 3, Operators explained within Scenarios
     */
    render() {
        return (
            <div> 
                <h3>Operators Within Scenarios</h3>
                <div className="scenario">
                    <h4>{this.state.operator[this.state.activeIndex]}</h4>
                    <Operators ref="operators" activeIndex={this.state.activeIndex} onChangeIndex={this.onChangeIndex.bind(this)} />
                    {this.state.activeIndex === 3 && <Button outline color="primary" onClick={ () => this.scenario4State()}>Change State</Button>}
                    <p>{this.state.explained[this.state.activeIndex]}</p>
                    <p>We can also represent this scenario by an equation, which is displayed below.</p>
                </div>

                <h3>Represent With An Equation</h3>
                <div className="scenario">
                    <div className="drag_things_to_boxes">
                        <div className="boxes">
                            <Box targetKey="box" editable={false} equation={this.state.equation} />
                        </div>
                    </div>
                    <p>{this.state.equationExplained[this.state.activeIndex]}</p>
                    {this.state.showScenarioButton && <Button outline color="primary" onClick={ () => this.nextScenario()}>Next Scenario</Button>}
                    {!this.state.showScenarioButton && <p>You can switch back between scenarios using the arrows on the image.</p>}
                </div>
            </div>
        )
    }
}

export default OnePart3;