import React, { Component } from 'react'
import Box from '../DragDrop/Box'
import '../../assets/css/ExpressionDrag.css'
import { Table, Button } from 'reactstrap'
import ComponentsXOR from '../DragDrop/ComponentsXOR'
import ComputeTruthTable from './ComputeTruthTable'

/**
 * Component for the logic and displaying of truth table with expression builder.
 * 
 * @example
 * return (
 *  <EquationTruth/>
 *  <EquationTruth equation={this.state.equation} editable={false}/>
 * )
 */
class EquationTruth extends Component {
  /**
   * Constructor for EquationTruth
   * @param {string} props.equation equation displayed within Box
   * @param {boolean} props.editable True means Draggable is enabled
   */
  constructor(props) {
    super(props);
    this.state = {
      equation: "",
      correct: false,
      headers: [],
      content: [],
      result: 0,
      offset: 0,
      editable: true,
      compressed: false,
      valid: false,
      tableButtonText: "Compress Table"
    }
  }

  /**
   * Handles setting initial state when component mounts
   */
  componentDidMount() {
    if(this.props.equation) {
      this.onChangeEquation(this.props.equation, true)
    }
    if(this.props.editable === false) {
      this.setState({editable: false})
    }
  }

    /**
     * Handles when component updates
     * @param {*} prevProps previous properties of the component
     */
    componentDidUpdate(prevProps) {
    if (this.props.equation && this.props.equation !== prevProps.equation && this.props.equation !== "") {
      this.setState({ equation: this.props.equation})
      this.onChangeEquation(this.props.equation, true)
    }
  }

  /**
   * Changes the equation binding to the Box, if correct the truth table is made
   * @param {string} newEquation the new equation to set the state too
   * @param {boolean} correct if equation is correct or not
   */
  onChangeEquation(newEquation, correct) {
    this.setState({
      equation: newEquation,
      correct: correct,
      headers: [],
      content: [],
      valid: false
    });
    if (correct === true && newEquation !== "") {
      let truth = new ComputeTruthTable(newEquation, this.compressed)
      this.setState({ equation: truth.getEquation(), content: truth.getContent(), headers: truth.getHeaders(), result: truth.getResult(), offset: truth.getOffset() })
      if (newEquation.length > 1) {
        this.setState({valid: true})
      }
    } 
  }

  /**
   * if key is the index of the result of the equation set to highlighted
   * if key is offset - 1 line is added for seperation of left hand side
   * @param {number} key index of value within headers
   */
  setStyle(key) {
    if (key === this.state.result) {
      return { backgroundColor: "#ffa" }
    } else if (key === this.state.offset - 1) {
      return { borderRight: "1px solid" }
    }
  }

  /**
   * Switches the truth table to compressed or not
   */
  switchCompress() {
    let compressed = this.state.compressed
    if(compressed) {
      compressed = false
      this.setState({tableButtonText: "Compress Table"})
    } else {
      compressed = true
      this.setState({tableButtonText: "Expand Table"})
    }
    let truth = new ComputeTruthTable(this.state.equation, compressed)
    this.setState({ equation: truth.getEquation(), content: truth.getContent(), headers: truth.getHeaders(), result: truth.getResult(), offset: truth.getOffset(), valid: true, compressed: compressed })
  }

  /**
   * Displays equation and truth table
   */
  render() {
    return (
        <div className="drag_things_to_boxes">
          <div className="things_to_drag">
            {this.state.editable && <ComponentsXOR />}
          </div>
          <div className="boxes">
            <Box targetKey="box" editable={this.state.editable} equation={this.state.equation} onChangeEquation={this.onChangeEquation.bind(this)} />
          </div>
          <Table className="truth" striped size="sm" responsive="sm" style={{ textAlign: "center", maxWidth: "100%", backgroundColor: "white" }}>
            <thead>
              <tr>
                {this.state.correct && this.state.headers.map((item, key) => {
                  return (
                    <th style={this.setStyle(key, item)} key={key}>{item}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.correct && this.state.content.map((element, key) => {
                return (
                  <tr key={key}>
                    {this.state.headers.map((value, index) => {
                      return (
                        <td key={index} style={this.setStyle(index, value)}>{element[index]}</td>
                        )
                    })}
                  </tr>)
              })}
            </tbody>
          </Table>
          {this.state.valid && <Button outline color="primary" onClick={() => this.switchCompress()}>{this.state.tableButtonText}</Button>}
        </div>
    );
  }
}

export default EquationTruth;